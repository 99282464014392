/*
  BOMMALI SNEHASHISH 
  1002127072
*/
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUser, FaBuilding, FaUsers, FaClipboardCheck, FaComment, FaAmbulance } from 'react-icons/fa';
import './Home.css';
import Footer from './Footer.js';

function Home() {
  const [showAIChatbox, setShowAIChatbox] = useState(false);
  const [activePage, setActivePage] = useState('home'); // New state to track active page

  const toggleAIChatbox = () => {
    setShowAIChatbox(!showAIChatbox);
  };
  // Function to change active page
  const navigate = (page) => {
    setActivePage(page);
  };
  return (
    <div className="home-container">
      <header className="top-header">
        <div className="welcome-message">
          <h1>Welcome to SmartHealth Hub</h1>
        </div>
        <div className="navigation-links">
        <Link to="http://Vsa5321.uta.cloud" className="nav-link">Blogs</Link>
          <Link to="/services" className="nav-link">Services</Link>
          <Link to="/contact" className="nav-link">Contact Us</Link>
          <Link to="/about" className="nav-link">About Us</Link>
        </div>
      </header>

      <div className="body-container">
        <div className="left-pane">
          <h2>Features</h2>
          <ul className="features">
            <li><FaUser className="button-icon" /> User-friendly Interface</li>
            <li><FaBuilding className="button-icon" /> Facility Management</li>
            <li><FaUsers className="button-icon" /> Staff Coordination</li>
            <li><FaClipboardCheck className="button-icon" /> Compliance Oversight</li>
            <li><FaAmbulance className="button-icon" /> Incident Response</li>
          </ul>
        </div>
        <div className="main-content">
          <p>SmartHealth Hub is a revolutionary platform that empowers individuals to take control of their health journey.
            With features like Symptom Checker, Medication Reminders, and Personal Health Records, we provide a comprehensive
            solution for managing your health.</p>
          <div className="action-buttons">
            <Link to="/login" className="login-button">Login</Link>
            <Link to="/signup" className="signup-button">Signup</Link>
          </div>
        </div>
        {showAIChatbox && (
          <div className="ai-chatbox-container">
            <div className="ai-chatbox">
              <div className="bot-message">Welcome! How can I assist you today?</div>
            </div>
            <div className="ai-chat-input-container">
              <input type="text" className="ai-chat-input" placeholder="Type your message..." />
              <button className="ai-send-button">Send</button>
            </div>
          </div>
        )}
        <div className={`ai-chat-icon ${showAIChatbox ? 'hidden' : ''}`} onClick={toggleAIChatbox}>
          <FaComment />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
