/*
  BOMMALI SNEHASHISH 
  1002127072
*/
// ContactUs.js

import React from 'react';
import './ContactUs.css'; // Create ContactUs.css for styling
import Footer from './Footer';

function ContactUs() {
  return (
    <div className="contact-us-container">
      <header className="contact-us-header">
        <h1>Contact Us</h1>
      </header>
      <div className="contact-us-content">
        <div className="contact-us-details">
          <h2>Our Location</h2>
          <p>123 Main Street, City, Country</p>
          <h2>Email Us</h2>
          <p>info@example.com</p>
          <h2>Call Us</h2>
          <p>+1 (123) 456-7890</p>
        </div>
        <div className="contact-us-form">
          <h2>Send Us a Message</h2>
          <form>
            <input type="text" placeholder="Your Name" />
            <input type="email" placeholder="Your Email" />
            <textarea placeholder="Your Message"></textarea>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
