/*Sangram Yathati- 1002168539*/
// AppointmentManagement.js
import React, { useEffect, useState } from "react";
import "./AppointmentManagement.css";
import axios from "axios";
import url from "../../../url";

const AppointmentForm = ({
  onSubmit,
  appointmentData,
  setAppointmentData,
  submitLabel,
  patients,
}) => (
  <form onSubmit={onSubmit}>
    <label>Patient Name</label>
        <select
          name="patient"
          style={{ height: "4rem" }}
          value={appointmentData.patient}
          placeholder="Patient Name"
          onChange={(e) =>
            setAppointmentData({ ...appointmentData, patient: e.target.value })
          }
          required
        >
          <label>Patient Name</label>
          {patients?.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
    <input
      type="text"
      placeholder="Doctor Name"
      value={appointmentData.doctorName}
      onChange={(e) =>
        setAppointmentData({ ...appointmentData, doctorName: e.target.value })
      }
      required
    />
    <input
      type="date"
      value={appointmentData.date}
      onChange={(e) =>
        setAppointmentData({ ...appointmentData, date: e.target.value })
      }
      required
    />
    <input
      type="time"
      value={appointmentData.time}
      onChange={(e) =>
        setAppointmentData({ ...appointmentData, time: e.target.value })
      }
      required
    />
    <button type="submit">{submitLabel}</button>
  </form>
);

const AppointmentManagement = () => {
  const [appointments, setAppointments] = useState([]);
  const [patients, setPatients] = useState([]);
  const [appointmentData, setAppointmentData] = useState({
    patientName: "",
    patient: "",
    doctorName: "",
    date: "",
    time: "",
  });
  
  const [editingId, setEditingId] = useState(null);
  const fetchPatients = async () => {
    try {
      const response = await axios.get(`${url}/user-two`);
      console.log(response);
      const filteredData = response.data.filter(
        (item) => item.role === "patient"
      );
      setPatients(filteredData);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAppointments = async () => {
    try {
      const response = await axios.get(`${url}/appointments`);
      console.log(response);
      setAppointments(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${url}/appointments`, {
        patient: appointmentData.patient,
        doctorName: appointmentData.doctorName,
        date: appointmentData.date,
        time: appointmentData.time,
      });
      console.log(response);
      fetchAppointments();
    } catch (error) {
      console.log(error);
    }
    // const newAppointment = {
    //   ...appointmentData,
    //   id: Date.now(),
    // };
    // setAppointments([...appointments, newAppointment]);
    setAppointmentData({
      patientName: "",
      patient: "",
      doctorName: "",
      date: "",
      time: "",
    });
  };

  const handleModifySubmit = async (event) => {
    event.preventDefault();
    // setAppointments(
    //   appointments.map((appointment) =>
    //     appointment.id === editingId
    //       ? { ...appointment, ...appointmentData }
    //       : appointment
    //   )
    // );
    try {
      const response = await axios.put(
        `${url}/appointments/${appointmentData.patient}`,
        {
          patient: appointmentData.patient,
          doctorName: appointmentData.doctorName,
          date: appointmentData.date,
          time: appointmentData.time,
        }
      );
      console.log(response);
      fetchAppointments();
    } catch (error) {
      console.log(error);
    }
    setEditingId(null);
    setAppointmentData({
      patientName: "",
      patient: "",
      doctorName: "",
      date: "",
      time: "",
    });
  };
  useEffect(() => {
    fetchAppointments();
    fetchPatients();
  }, []);

  return (
    <div className="appointment-management">
      <h1>Appointment Management</h1>
      {editingId == null ? (
        <AppointmentForm
          onSubmit={handleAddSubmit}
          appointmentData={appointmentData}
          setAppointmentData={setAppointmentData}
          submitLabel="Add Appointment"
          patients = {patients}
        />
      ) : (
        <AppointmentForm
          onSubmit={handleModifySubmit}
          appointmentData={appointmentData}
          setAppointmentData={setAppointmentData}
          submitLabel="Update Appointment"
          patients = {patients}
        />
      )}

      <ul className="appointments-list">
        <li className="list-heading">
          <span>Patient Name</span>
          {/* <span>Patient ID</span> */}
          <span>Doctor Name</span>
          <span>Date</span>
          <span>Time</span>
          <span>Actions</span>
        </li>
        {appointments.map((appointment, index) => (
          <li key={appointment.id} className="appointment-item">
            <span>{appointment.patientDetails?.name}</span>
            {/* <span>{appointment.patient}</span> */}
            <span>{appointment.doctorName}</span>
            <span>{appointment.date}</span>
            <span>{appointment.time}</span>
            <button
              onClick={() => {
                setEditingId(appointment.id);
                setAppointmentData(appointment);
              }}
            >
              Edit
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AppointmentManagement;
