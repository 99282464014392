//Samyukth challa 1002075109
// src/components/HealthcareAdministrator/HealthcareAdministrator.js
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./HealthcareAdministrator.css";
import {
  FaUser,
  FaCog,
  FaSignOutAlt,
  FaBuilding,
  FaUsers,
  FaClipboardCheck,
  FaExclamationTriangle,
  FaBars,
} from "react-icons/fa";

function HealthcareAdministrator() {
  const navigate = useNavigate();
  const handleNavigate = (e) => {
    navigate(`/healthadmin/${e.target.name}`);
  };
  const handleLogout = () => {
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("userRole");
    navigate("/login");
  };

  return (
    <div className="healthcare-admin-container">
      {/* Top Bar */}
      <div className="top-bar">
        <h1 className="healthcare-admin-heading">
          Welcome Healthcare Administrator!
        </h1>
        <div className="top-bar-buttons">
          <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={handleLogout}
          >
            <FaSignOutAlt className="icon" /> Logout
          </div>
        </div>
      </div>

      <div className="healthcare-admin-content">
        <div className="healthcare-admin-grid">
          <div className="healthcare-admin-section">
            <h2>
              <FaBuilding />
              Facility Management
            </h2>
            <p>
              Oversee the management of healthcare facilities within the
              SmartHealth Hub platform. Maintain details about each facility,
              including location, services offered, and operational status.
            </p>
            <button
              name="facility"
              className="healthcare-admin-button"
              onClick={handleNavigate}
            >
              Manage Facilities
            </button>
          </div>

          <div className="healthcare-admin-section">
            <h2>
              <FaUsers />
              Staff Coordination
            </h2>
            <p>
              Coordinate and manage healthcare staff accounts within the
              platform. Ensure that healthcare providers and pharmacists have
              appropriate access levels and permissions.
            </p>
            <button
              name="staff"
              className="healthcare-admin-button"
              onClick={handleNavigate}
            >
              Manage Staff
            </button>
          </div>

          <div className="healthcare-admin-section">
            <h2>
              <FaClipboardCheck />
              Compliance Oversight
            </h2>
            <p>
              Monitor and ensure that the platform adheres to healthcare
              regulations, standards, and legal requirements. Collaborate with
              system administrators and other stakeholders to address
              compliance-related issues.
            </p>
            <button
              name="compliance"
              className="healthcare-admin-button"
              onClick={handleNavigate}
            >
              Monitor Compliance
            </button>
          </div>

          <div className="healthcare-admin-section">
            <h2>
              <FaExclamationTriangle />
              Incident Response
            </h2>
            <p>
              Coordinate responses to health-related incidents or emergencies
              that may occur within the SmartHealth Hub ecosystem. Ensure a
              timely and effective response to incidents, collaborating with
              healthcare providers and other roles.
            </p>
            <button
              name="incident"
              className="healthcare-admin-button"
              onClick={handleNavigate}
            >
              Respond to Incidents
            </button>
          </div>

          <div className="admin-section">
            <h2>Chat</h2>
            <p>For communications</p>
            <button className="admin-button" onClick={() => navigate("/chat")}>
              Community Chat
            </button>{" "}
            {/* Button to navigate to the Report Generation page */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HealthcareAdministrator;
