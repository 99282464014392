/*
  BOMMALI SNEHASHISH 
  1002127072
*/
import React, { useEffect, useState } from "react";
import "./ConfigurationSettings.css";
import axios from "axios";
import url from "../../../url";

function ConfigurationSettings() {
  const [theme, setTheme] = useState("light");
  const [language, setLanguage] = useState("English");
  const [dateFormat, setDateFormat] = useState("MM/DD/YYYY");
  const [smtpServer, setSmtpServer] = useState("");
  const [logLevel, setLogLevel] = useState("info");
  const [monitoringThreshold, setMonitoringThreshold] = useState("");
  const [backupSchedule, setBackupSchedule] = useState("Daily");
  const [auditTrailEnabled, setAuditTrailEnabled] = useState(false);
  const [versionControlEnabled, setVersionControlEnabled] = useState(false);

  useEffect(() => {
    const fetchSystemConfiguration = async () => {
      try {
        const response = await axios.get(`${url}/admin/system-configure`);
        const systemConfiguration = response.data.config;
        setTheme(systemConfiguration.theme);
        setLanguage(systemConfiguration.language);
        setDateFormat(systemConfiguration.dateFormat);
        setSmtpServer(systemConfiguration.smtpServer);
        setLogLevel(systemConfiguration.logLevel);
        setMonitoringThreshold(systemConfiguration.monitoringThreshold);
        setBackupSchedule(systemConfiguration.backupSchedule);
        setAuditTrailEnabled(systemConfiguration.auditTrailEnabled);
        setVersionControlEnabled(systemConfiguration.versionControlEnabled);
      } catch (error) {
        console.log(error);
      }
    };

    fetchSystemConfiguration();
  }, []);

  const handleThemeChange = (selectedTheme) => {
    setTheme(selectedTheme);
  };

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };

  const handleDateFormatChange = (selectedDateFormat) => {
    setDateFormat(selectedDateFormat);
  };

  const handleSmtpServerChange = (value) => {
    setSmtpServer(value);
  };

  const handleLogLevelChange = (selectedLogLevel) => {
    setLogLevel(selectedLogLevel);
  };

  const handleMonitoringThresholdChange = (value) => {
    setMonitoringThreshold(value);
  };

  const handleBackupScheduleChange = (selectedSchedule) => {
    setBackupSchedule(selectedSchedule);
  };

  const handleAuditTrailToggle = () => {
    setAuditTrailEnabled(!auditTrailEnabled);
  };

  const handleVersionControlToggle = () => {
    setVersionControlEnabled(!versionControlEnabled);
  };
  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${url}/admin/system-configure`, {
        theme: theme,
        language: language,
        dateFormat: dateFormat,
        smtpServer: smtpServer,
        logLevel: logLevel,
        monitoringThreshold: monitoringThreshold,
        backupSchedule: backupSchedule,
        auditTrailEnabled: auditTrailEnabled,
        versionControlEnabled: versionControlEnabled,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="configuration-container">
      {/* Theme Configuration */}
      <div className="configuration-section">
        <h3>Theme Configuration</h3>
        <select
          value={theme}
          onChange={(e) => handleThemeChange(e.target.value)}
        >
          <option value="light">Light</option>
          <option value="dark">Dark</option>
          {/* Add more theme options */}
        </select>
      </div>
      {/* Language Configuration */}
      <div className="configuration-section">
        <h3>Language Configuration</h3>
        <select
          value={language}
          onChange={(e) => handleLanguageChange(e.target.value)}
        >
          <option value="English">English</option>
          <option value="Spanish">Spanish</option>
          {/* Add more language options */}
        </select>
      </div>
      {/* Date Format Configuration */}
      <div className="configuration-section">
        <h3>Date Format Configuration</h3>
        <select
          value={dateFormat}
          onChange={(e) => handleDateFormatChange(e.target.value)}
        >
          <option value="MM/DD/YYYY">MM/DD/YYYY</option>
          <option value="DD/MM/YYYY">DD/MM/YYYY</option>
          <option value="YYYY-MM-DD">YYYY-MM-DD</option>
          {/* Add more date format options */}
        </select>
      </div>
      {/* SMTP Server Configuration */}
      <div className="configuration-section">
        <h3>SMTP Server Configuration</h3>
        <input
          type="text"
          value={smtpServer}
          onChange={(e) => handleSmtpServerChange(e.target.value)}
        />
      </div>
      {/* Log Level Configuration */}
      <div className="configuration-section">
        <h3>Log Level Configuration</h3>
        <select
          value={logLevel}
          onChange={(e) => handleLogLevelChange(e.target.value)}
        >
          <option value="info">Info</option>
          <option value="warn">Warning</option>
          <option value="error">Error</option>
          {/* Add more log level options */}
        </select>
      </div>
      {/* Monitoring Threshold Configuration */}
      <div className="configuration-section">
        <h3>Monitoring Threshold Configuration</h3>
        <input
          type="text"
          value={monitoringThreshold}
          onChange={(e) => handleMonitoringThresholdChange(e.target.value)}
        />
      </div>
      {/* Backup Schedule Configuration */}
      <div className="configuration-section">
        <h3>Backup Schedule Configuration</h3>
        <select
          value={backupSchedule}
          onChange={(e) => handleBackupScheduleChange(e.target.value)}
        >
          <option value="Daily">Daily</option>
          <option value="Weekly">Weekly</option>
          <option value="Monthly">Monthly</option>
          {/* Add more backup schedule options */}
        </select>
      </div>
      {/* Audit Trail Configuration */}
      <div className="configuration-section">
        <h3>Audit Trail Configuration</h3>
        <label>
          <input
            type="checkbox"
            checked={auditTrailEnabled}
            onChange={handleAuditTrailToggle}
          />
          Enable Audit Trail
        </label>
      </div>
      {/* Version Control Configuration */}
      <div className="configuration-section">
        <h3>Version Control Configuration</h3>
        <label>
          <input
            type="checkbox"
            checked={versionControlEnabled}
            onChange={handleVersionControlToggle}
          />
          Enable Version Control
        </label>
      </div>
      {/* Save Button */}
      <button onClick={handleSubmit} className="save-button">
        Save Configuration
      </button>
    </div>
  );
}

export default ConfigurationSettings;
