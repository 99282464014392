/*Sangram Yathati- 1002168539*/

import React from 'react';
import './ProfessionalCollaboration.css';

const forums = [
  {
    title: 'Global Health Forum',
    content: 'Explore new advances in global medication practices, including novel treatments for chronic diseases, strategies for vaccine distribution, and collaborative research initiatives.',
    link: 'https://www.globalhealthforum.example.com'
  },
  {
    title: 'World Health Forum',
    content: 'Discuss the impact of international health policies on medication availability and the development of new drugs, with a special focus on responding to global health crises.',
    link: 'https://www.worldhealthforum.example.com'
  },
  {
    title: 'Critical Case Health Forum',
    content: 'Examine case studies on the use of advanced medications in critical care, from emergency treatments to managing complex conditions with cutting-edge pharmaceuticals.',
    link: 'https://www.criticalcasehealthforum.example.com'
  },
];

const ForumEntry = ({ forum }) => (
  <div className="forum-entry">
    <h2>{forum.title}</h2>
    <p>{forum.content}</p>
    <button className="access-forum-btn" onClick={() => window.open(forum.link, '_blank')}>Access Forum</button>
  </div>
);

const ProfessionalCollaboration = () => {
  return (
    <div className="professional-collaboration">
      <h1>Professional Collaboration</h1>
      {forums.map((forum, index) => (
        <ForumEntry key={index} forum={forum} />
      ))}
    </div>
  );
};

export default ProfessionalCollaboration;
