/*
  BOMMALI SNEHASHISH 
  1002127072
*/
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ProviderList.css";
import EditProviderListModal from "./EditProviderListModal";
import axios from "axios";
import url from "../../../url";

const dummyProviders = [
  {
    id: 1,
    name: "Healthcare Provider 1",
    specialization: "Cardiology",
    isActive: true,
  },
  {
    id: 2,
    name: "Healthcare Provider 2",
    specialization: "Dermatology",
    isActive: false,
  },
  {
    id: 3,
    name: "Healthcare Provider 3",
    specialization: "Neurology",
    isActive: true,
  },
  {
    id: 4,
    name: "Healthcare Provider 4",
    specialization: "Orthopedics",
    isActive: true,
  },
  {
    id: 5,
    name: "Healthcare Provider 5",
    specialization: "Pediatrics",
    isActive: true,
  },
];

function ProviderList() {
  const [providers, setProviders] = useState([]);
  const [newProviderName, setNewProviderName] = useState("");
  const [newProviderSpecialization, setNewProviderSpecialization] =
    useState("");
  const [editProviderId, setEditProviderId] = useState(null);
  const navigate = useNavigate();
  const fetchHealthCareProvider = async () => {
    try {
      const response = await axios.get(`${url}/health-care-providers`);
      console.log(response);
      setProviders(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchHealthCareProvider();
  }, []);

  const toggleProviderStatus = async (providerId, status, name, spec) => {
    try {
      const response = await axios.put(
        `${url}/health-care-providers/${providerId}`,
        {
          name: name,
          specialization: spec,
          status: status === "Deactivate" ? "inactive" : "active",
        }
      );
      console.log(response);
      fetchHealthCareProvider();
    } catch (error) {
      console.log(error);
    }
  };

  const viewProviderDetails = (provider) => {
    alert(
      `Viewing details for ${provider.name} - Specialization: ${
        provider.specialization
      } - Status: ${provider.isActive ? "Active" : "Inactive"}`
    );
    navigate(`/provider-details/${provider.id}`);
  };

  const editProvider = (providerId) => {
    setEditProviderId(providerId);
  };
  const closeModal = () => {
    setEditProviderId(null);
  };

  //   const updateProvider = (providerId, providerData) => {
  //     const updatedProviders = providers.map((provider) => {
  //       if (provider.id === providerId) {
  //         return { ...provider, ...providerData };
  //       }
  //       return provider;
  //     });
  //     setProviders(updatedProviders);
  //     closeModal();
  //   };

  const addProvider = async () => {
    try {
      const response = await axios.post(`${url}/health-care-providers/`, {
        name: newProviderName,
        specialization: newProviderSpecialization,
        status: "active",
      });
      console.log(response);
      fetchHealthCareProvider();
    } catch (error) {
      console.log(error);
    }
    setNewProviderName("");
    setNewProviderSpecialization("");
  };

  const deleteProvider = async (providerId) => {
    try {
      const response = await axios.delete(
        `${url}/health-care-providers/${providerId}`
      );
      console.log(response);
      fetchHealthCareProvider();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h2>Healthcare Provider Management</h2>
      <div>
        <label>Name:</label>
        <input
          type="text"
          value={newProviderName}
          onChange={(e) => setNewProviderName(e.target.value)}
        />

        <label>Specialization:</label>
        <input
          type="text"
          value={newProviderSpecialization}
          onChange={(e) => setNewProviderSpecialization(e.target.value)}
        />
      </div>
      <button onClick={addProvider}>Add Provider</button>
      <table
        style={{ width: "80%", margin: "0 auto", borderCollapse: "collapse" }}
      >
        <caption>List of Healthcare Providers</caption>
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f2f2f2",
              }}
            >
              ID
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f2f2f2",
              }}
            >
              Name
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f2f2f2",
              }}
            >
              Specialization
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f2f2f2",
              }}
            >
              Status
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f2f2f2",
              }}
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {providers.map((provider) => (
            <tr key={provider.id}>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {provider.id}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {provider.name}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {provider.specialization}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {provider.status === "active" ? "Active" : "Inactive"}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                <button onClick={() => viewProviderDetails(provider)}>
                  View
                </button>
                <button onClick={() => editProvider(provider.id)}>Edit</button>
                <button
                  onClick={() =>
                    toggleProviderStatus(
                      provider.id,
                      provider.status === "active" ? "Deactivate" : "Activate",
                      provider.name,
                      provider.specialization
                    )
                  }
                >
                  {provider.status === "active" ? "Deactivate" : "Activate"}
                </button>
                <button onClick={() => deleteProvider(provider.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editProviderId !== null && (
        <EditProviderListModal
          provider={providers.find(
            (provider) => provider.id === editProviderId
          )}
          setProviders={setProviders}
          closeModal={closeModal}
        />
      )}
    </div>
  );
}

export default ProviderList;
