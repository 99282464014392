/*
  BOMMALI SNEHASHISH 
  1002127072
*/

// src/App.js

// Importing necessary dependencies from React and React Router for SPA functionality
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Importing components for public-facing pages
import Home from './components/Home/Home';
import Services from './components/Home/Services';
import ContactUs from './components/Home/ContactUs';
import AboutUs from './components/Home/AboutUs';

// Importing authentication related components
import Login from './components/Auth/Login/Login';
import Signup from './components/Auth/Signup/Signup';

// Import dashboard components for different user roles
import AdministratorDashboard from './components/Administrator/Administrator';
import PatientDashboard from './components/Patient/Patient';
import HealthcareProviderDashboard from './components/HealthcareProvider/HealthcareProvider';
import PharmacistDashboard from './components/Pharmacist/Pharmacist';
import HealthcareAdministratorDashboard from './components/HealthcareAdministrator/HealthcareAdministrator';

// Importing Administrator feature components
import UserList from './components/Administrator/UserManagement/UserList';
import ProviderList from './components/Administrator/HealthcareProviderManagement/ProviderList';
import ConfigurationSettings from './components/Administrator/SystemConfiguration/ConfigurationSettings';
import DataMonitoring from './components/Administrator/DataOversight/DataMonitoring';
import ReportGeneration from './components/Administrator/ReportGeneration/ReportGeneration';

// Importing Healthcare Provider feature components
import EPrescriptions from './components/HealthcareProvider/E-Prescriptions/EPrescriptions';
import ProfessionalCollaboration from './components/HealthcareProvider/ProfessionalCollaboration/ProfessionalCollaboration';
import AppointmentManagement from './components/HealthcareProvider/AppointmentManagement/AppointmentManagement';
import HealthRecordsAccess from './components/HealthcareProvider/PatientHealthRecords/HealthRecordsAccess';
import SecureMessaging from './components/HealthcareProvider/SecureMessaging/SecureMessaging';
import AnalyticsDashboard from './components/HealthcareProvider/AnalyticsDashboard/AnalyticsDashboard';

// Importing Healthcare Administrator feature components
import Facility from './HealthAdmin/FacilityManagement';
import Staff from './HealthAdmin/StaffCoordination';
import Compliance from './HealthAdmin/Compliance';
import Incident from './HealthAdmin/Incident';

// Importing component for protected route functionality
import PrivateRoute from './PrivateRoute';
import Message from './components/Chat';

function App() {
  return (
    <Router>
      <Routes>
        {/* Defining Route components for navigation between different parts of the application */}
        
        {/* Public routes */}
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/chat" element={<Message/>} />
        <Route path="*" element={<div>Page Not Found</div>} />
       

        {/* Protected routes requiring authentication */}
        {/* Dashboard routes for different user roles */}
        <Route path="/administrator-dashboard" element={<PrivateRoute><AdministratorDashboard /></PrivateRoute>} />
        <Route path="/patient-dashboard" element={<PrivateRoute><PatientDashboard /></PrivateRoute>} />
        <Route path="/healthcare-provider-dashboard" element={<PrivateRoute><HealthcareProviderDashboard /></PrivateRoute>} />
        <Route path="/pharmacist-dashboard" element={<PrivateRoute><PharmacistDashboard /></PrivateRoute>} />
        <Route path="/healthcare-administrator-dashboard" element={<PrivateRoute><HealthcareAdministratorDashboard /></PrivateRoute>} />

        {/* Administrator-specific feature routes */}
        <Route path="/user-management" element={<PrivateRoute><UserList /></PrivateRoute>} />
        <Route path="/provider-management" element={<PrivateRoute><ProviderList /></PrivateRoute>} />
        <Route path="/system-configuration" element={<PrivateRoute><ConfigurationSettings /></PrivateRoute>} />
        <Route path="/data-monitoring" element={<PrivateRoute><DataMonitoring /></PrivateRoute>} />
        <Route path="/report-generation" element={<PrivateRoute><ReportGeneration /></PrivateRoute>} />

        {/* Healthcare Provider-specific feature routes */}
        <Route path="/e-prescriptions" element={<PrivateRoute><EPrescriptions /></PrivateRoute>} />
        <Route path="/appointment-management" element={<PrivateRoute><AppointmentManagement /></PrivateRoute>} />
        <Route path="/patient-health-records" element={<PrivateRoute><HealthRecordsAccess /></PrivateRoute>} />
        <Route path="/professional-collaboration" element={<PrivateRoute><ProfessionalCollaboration /></PrivateRoute>} />
        <Route path="/secure-messaging" element={<PrivateRoute><SecureMessaging /></PrivateRoute>} />
        <Route path="/analytics-dashboard" element={<PrivateRoute><AnalyticsDashboard /></PrivateRoute>} />

        {/* Healthcare Administrator-specific feature routes */}
        <Route path="/healthadmin/facility" element={<PrivateRoute><Facility /></PrivateRoute>} />
        <Route path="/healthadmin/staff" element={<PrivateRoute><Staff /></PrivateRoute>} />
        <Route path="/healthadmin/incident" element={<PrivateRoute><Incident /></PrivateRoute>} />
        <Route path="/healthadmin/compliance" element={<PrivateRoute><Compliance /></PrivateRoute>} />
      </Routes>
    </Router>
  );
}

// Exporting the App component to be used in index.js
export default App;
