/*
  BOMMALI SNEHASHISH 
  1002127072
*/
// src/components/Administrator/ProviderManagement/EditProviderListModal.js
import axios from "axios";
import React, { useState } from "react";
import url from "../../../url";

function EditProviderListModal({ provider, setProviders, closeModal }) {
  const [name, setName] = useState(provider.name);
  const [specialization, setSpecialization] = useState(provider.specialization);
  const [status, setStatus] = useState(provider.status);
  const [id, setId] = useState(provider.id);
  const fetchHealthCareProvider = async () => {
    try {
      const response = await axios.get(`${url}/health-care-providers`);
      console.log(response);
      setProviders(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async () => {
    try {
      const response = await axios.put(`${url}/health-care-providers/${id}`, {
        name: name,
        specialization: specialization,
        status: status,
      });
      console.log(response);
      fetchHealthCareProvider();
    } catch (error) {
      console.log(error);
    }
    closeModal();
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <h2>Edit Provider</h2>
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <label>Specialization:</label>
        <input
          type="text"
          value={specialization}
          onChange={(e) => setSpecialization(e.target.value)}
        />
        <button onClick={handleSubmit}>Save Changes</button>
      </div>
    </div>
  );
}

export default EditProviderListModal;
