//Surya Sri Vrathameswara Reddy Alla - 1002075321
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import url from "../../url";
import { useNavigate } from "react-router-dom";
import {
  FaUser,
  FaCog,
  FaSignOutAlt,
  FaPills,
  FaHistory,
  FaComments,
  FaBars,
  FaArrowLeft,
  FaPaperPlane,
} from "react-icons/fa";
import "./Pharmacist.css";
import { DatePicker, Form, Input, InputNumber, Modal, Select } from "antd";
import axios from "axios";

function Pharmacist() {
  const navigate = useNavigate();
  const [page, setPage] = useState("home");
  const [reply, setReply] = useState({});
  const [patients, setPatients] = useState([]);
  const [medicationDispensation] = Form.useForm();
  const [medicationHistory] = Form.useForm();
  const [medicationDispensationData, setMedicationDispensationData] = useState(
    []
  );
  const [medicationHistoryData, setMedicationHistoryData] = useState([]);
  const [messages, setMessages] = useState([
    {
      id: 1,
      from: "John Doe",
      content: "Can I take Amoxicillin with my current medications?",
      replies: [],
    },
    {
      id: 2,
      from: "Jane Smith",
      content: "What should I do if I experience side effects from Ibuprofen?",
      replies: [],
    },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    medicationDispensation.submit();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const handleOk2 = () => {
    medicationHistory.submit();
    setIsModalOpen2(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const fetchPatients = async () => {
    try {
      const response = await axios.get(`${url}/user-two`);
      console.log(response);
      const filteredData = response.data.filter(
        (item) => item.role === "patient"
      );
      setPatients(filteredData);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPatients();
  }, []);

  const handleCreate = async (values) => {
    try {
      const response = await axios.post(`${url}/medication-dispensations`, {
        ...values,
      });
      console.log(response);
      fetchMedicationDispensation();
    } catch (error) {
      console.log(error);
    }
    medicationDispensation.resetFields();
  };

  const handleCreateMedicationHistory = async (values) => {
    try {
      const response = await axios.post(`${url}/medication-history`, {
        ...values,
        condtitions: "test",
      });
      console.log(response);
      fetchMedicationHistory();
    } catch (error) {
      console.log(error);
    }
    medicationHistory.resetFields();
  };

  const fetchMedicationDispensation = async () => {
    try {
      const response = await axios.get(`${url}/medication-dispensations`);
      console.log(response.data);
      setMedicationDispensationData(response.data);
    } catch (error) {
      console.log(error);
    }
    medicationDispensation.resetFields();
  };

  const fetchMedicationHistory = async () => {
    try {
      const response = await axios.get(`${url}/medication-history`);
      console.log(response.data);
      setMedicationHistoryData(response.data);
    } catch (error) {
      console.log(error);
    }
    medicationHistory.resetFields();
  };

  useEffect(() => {
    fetchMedicationDispensation();
    fetchMedicationHistory();
  }, []);

  const renderContent = () => {
    switch (page) {
      case "dispenseMedication":
        return (
          <div className="content-container">
            <h2>Medication Dispensation</h2>
            <table className="table">
              <thead>
                <tr>
                  <th>Medication</th>
                  <th>Dosage</th>
                  <th>Patient</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {medicationDispensationData.map((itm) => {
                  return (
                    <>
                      <tr>
                        <td>{itm.medication}</td>
                        <td>{itm.dosage}</td>
                        <td>{itm.patient}</td>
                        <td>{itm.date}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            <div
              className="back-btn"
              style={{ marginRight: "10px" }}
              onClick={showModal}
            >
              Create New
            </div>
            <div className="back-btn" onClick={() => setPage("home")}>
              <FaArrowLeft /> Back to Home
            </div>
            <Modal
              title="New Medication"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <Form
                form={medicationDispensation}
                onFinish={(values) => {
                  handleCreate(values);
                }}
              >
                <Form.Item name="medication" label="Medication">
                  <Input placeholder="enter a medication name"></Input>
                </Form.Item>
                <Form.Item name="dosage" label="Dosage">
                  <InputNumber
                    style={{ width: "15rem" }}
                    placeholder="enter dosage"
                  ></InputNumber>
                </Form.Item>
                <Form.Item name="date" label="Date">
                  <DatePicker />
                </Form.Item>
                <Form.Item name="patient" label="Patient">
                  <Select
                    placeholder="Please select"
                    options={patients.map((item) => ({
                      label: item.username,
                      value: item.id,
                    }))}
                  />
                </Form.Item>
              </Form>
            </Modal>
          </div>
        );
      case "viewMedicationHistory":
        return (
          <div className="content-container">
            <h2>Medication History</h2>
            <table className="table">
              <thead>
                <tr>
                  <th>Patient</th>
                  <th>Medication</th>
                  <th>Dosage</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                {medicationHistoryData.map((itm) => {
                  return (
                    <>
                      <tr>
                        <td>{itm?.patient}</td>
                        <td>{itm?.medication}</td>
                        <td>{itm?.dosage}</td>
                        <td>{itm?.duration}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            <div
              className="back-btn"
              style={{ marginRight: "10px" }}
              onClick={showModal2}
            >
              Create New
            </div>
            <div className="back-btn" onClick={() => setPage("home")}>
              <FaArrowLeft /> Back to Home
            </div>
            <Modal
              title="New Medication"
              open={isModalOpen2}
              onOk={handleOk2}
              onCancel={handleCancel2}
            >
              <Form
                form={medicationHistory}
                onFinish={(values) => {
                  handleCreateMedicationHistory(values);
                }}
              >
                <Form.Item name="medication" label="Medication">
                  <Input placeholder="enter a medication name"></Input>
                </Form.Item>
                <Form.Item name="dosage" label="Dosage">
                  <InputNumber
                    style={{ width: "15rem" }}
                    placeholder="enter dosage"
                  ></InputNumber>
                </Form.Item>
                <Form.Item name="duration" label="Duration">
                  <DatePicker />
                </Form.Item>
                <Form.Item name="patient" label="Patient">
                  <Select
                    placeholder="Please select"
                    options={patients.map((item) => ({
                      label: item.username,
                      value: item.id,
                    }))}
                  />
                </Form.Item>
              </Form>
            </Modal>
          </div>
        );
      case "sendSecureMessage":
        return (
          <div className="admin-section">
            <h2>Chat</h2>
            <p>For communications</p>
            <button className="admin-button" onClick={() => navigate("/chat")}>
              Community Chat
            </button>{" "}
            {/* Button to navigate to the Report Generation page */}
          </div>
        );
      default:
        return (
          <div className="pharmacist-content">
            <div className="pharmacist-grid">
              <div className="pharmacist-section">
                <h2>
                  <FaPills /> Medication Dispensation
                </h2>
                <p>
                  Pharmacists are responsible for dispensing medications
                  prescribed by healthcare providers. When a healthcare provider
                  electronically prescribes a medication for a patient, the
                  pharmacist receives the prescription information and dispenses
                  the medication to the patient.
                </p>
                <button
                  className="option-btn"
                  onClick={() => setPage("dispenseMedication")}
                >
                  <FaPills /> Dispense Medication
                </button>
              </div>

              <div className="pharmacist-section">
                <h2>
                  <FaHistory /> Medication History
                </h2>
                <p>
                  Pharmacists have access to the medication history of patients,
                  which helps in ensuring that prescribed medications are
                  suitable, considering potential interactions with other drugs,
                  allergies, or existing medical conditions.
                </p>
                <button
                  className="option-btn"
                  onClick={() => setPage("viewMedicationHistory")}
                >
                  <FaHistory /> View Medication History
                </button>
              </div>

              <div className="pharmacist-section">
                <h2>
                  <FaComments /> Communication
                </h2>
                <p>
                  Secure messaging allows pharmacists to communicate with
                  patients regarding medication-related inquiries, providing
                  guidance on proper usage, potential side effects, or
                  addressing any concerns the patient may have.
                </p>
                <button
                  className="option-btn"
                  onClick={() => setPage("sendSecureMessage")}
                >
                  <FaComments /> Secure Communication
                </button>
              </div>
            </div>
          </div>
        );
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("userRole");
    navigate("/login");
  };

  return (
    <div className="pharmacist-container">
      {/* Top Bar */}
      <div className="top-bar">
        <h1 className="pharmacist-heading">Welcome Pharmacist!</h1>
        <div className="top-bar-buttons">
          <Link to="/profile">
            <FaUser className="icon" /> Profile
          </Link>
          <Link to="/menu">
            <FaBars className="icon" /> Menu
          </Link>
          <Link to="/settings">
            <FaCog className="icon" /> Settings
          </Link>
          <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={handleLogout}
          >
            <FaSignOutAlt className="icon" /> Logout
          </div>
        </div>
      </div>

      {/* Pharmacist Content */}
      {renderContent()}
    </div>
  );
}

export default Pharmacist;
