/* Samyukth challa 1002075109
 */
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  IconButton,
  FormHelperText,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import url from "../../../url";

const ComplianceTable = ({ dummydata }) => {
  const [data, setData] = useState(dummydata);
  const [page, setPage] = React.useState(0);
  const [selectedRow, setSelectdRow] = useState({});
  const [editForm, setShowEditForm] = React.useState(false);
  const [isEdit, setIsEdit] = useState(-1);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    department: "",
    complianceArea: "",
    complianceStatus: "",
    lastInspection: "",
    taskName: "",
    taskStatus: "",
  });
  const fetchStaffData = async () => {
    try {
      const response = await axios.get(`${url}/compliance`);
      console.log(response);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchStaffData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClose = () => {
    setShowEditForm(false);
    setFormData({
      department: "",
      complianceArea: "",
      complianceStatus: "",
      lastInspection: "",
      taskName: "",
      taskStatus: "",
    });
    setIsEdit(-1);
  };

  const handleSubmit = async (event) => {
    if (isEdit > 0) {
      const newData = [...data];
      newData[isEdit - 1] = { ...formData, id: isEdit };
      try {
        const response = await axios.put(`${url}/compliance/${isEdit}`, {
          ...formData,
        });
        console.log(response);
        fetchStaffData();
      } catch (error) {
        console.log(error);
      }
    } else {
      const newData = [
        {
          id: data.length + 1,
          ...formData,
        },
        ...data,
      ];
      try {
        const response = await axios.post(`${url}/compliance`, {
          ...formData,
        });
        console.log(response);
        fetchStaffData();
      } catch (error) {
        console.log(error);
      }
    }
    event.preventDefault();
    console.log(formData);
    handleClose();
    setIsEdit(-1);
  };

  const handleEdit = (item) => {
    setIsEdit(item.id);
    setSelectdRow(item);
    setFormData(item);
    setShowEditForm(true);
  };
  const handleAdd = () => {
    setShowEditForm(true);
    setFormData({
      department: "",
      complianceArea: "",
      complianceStatus: "",
      lastInspection: "",
      taskName: "",
      taskStatus: "",
    });
  };
  return (
    <Grid style={{ width: "100%" }}>
      <Button
        style={{ margin: "0 3rem 3rem" }}
        onClick={handleAdd}
        variant="contained"
      >
        Add Compliance
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Compliance Area</TableCell>
              <TableCell>Compliance Status</TableCell>
              <TableCell>Last Inspection</TableCell>
              <TableCell>Task Name</TableCell>
              <TableCell>Task Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.department}</TableCell>
                <TableCell>{row.complianceArea}</TableCell>
                <TableCell>{row.complianceStatus}</TableCell>
                <TableCell>{row.lastInspection?.toLocaleDateString()}</TableCell>
                <TableCell>{row.taskName}</TableCell>
                <TableCell>{row.taskStatus}</TableCell>
                <TableCell>
                  <Button
                    variant="text"
                    onClick={() => {
                      handleEdit(row);
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={editForm} onClose={handleClose}>
        <DialogTitle>
          Form
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="department"
                  label="Department"
                  required
                  value={formData.department}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="complianceArea"
                  label="Compliance Area"
                  value={formData.complianceArea}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Compliance Status</InputLabel>
                  <Select
                    required
                    name="complianceStatus"
                    value={formData.complianceStatus}
                    onChange={handleChange}
                  >
                    <MenuItem value="Compliant">Compliant</MenuItem>
                    <MenuItem value="In Progress">In Progress</MenuItem>
                    <MenuItem value="Non-Compliant">Non-Compliant</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  type="date"
                  name="lastInspection"
                  label="Last Inspection"
                  value={formData.lastInspection}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  name="taskName"
                  label="Task Name"
                  value={formData.taskName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Task Status</InputLabel>
                  <Select
                    required
                    name="taskStatus"
                    value={formData.taskStatus}
                    onChange={handleChange}
                  >
                    <MenuItem value="Due">Due</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ComplianceTable;
