/*
  BOMMALI SNEHASHISH 
  1002127072
*/
// src/components/Administrator/UserManagement/UserList.js
import React, { useState, useEffect } from "react";
import "./UserList.css";
import EditUserModal from "./EditUserModal";
import axios from "axios";
import url from "../../../url";
import { Password } from "@mui/icons-material";

const dummyUsers = [
  {
    id: 1,
    name: "Snehashish Bommali",
    email: "bommali@gmail.com",
    role: "Admin",
    isActive: true,
  },
  {
    id: 2,
    name: "Sangram Yathat",
    email: "sangram@gmail.com",
    role: "Patient",
    isActive: false,
  },
  {
    id: 3,
    name: "Sulakshana Thirunagari",
    email: "sulakshana@gmail.com",
    role: "Pharmacist",
    isActive: true,
  },
  {
    id: 4,
    name: "Samyukth Challa",
    email: "samyukth@gmail.com",
    role: "healthadmin",
    isActive: true,
  },
  {
    id: 5,
    name: "Surya Alla",
    email: "surya@gmail.com",
    role: "HealthcareProvider",
    isActive: true,
  },
];

function UserList() {
  const [users, setUsers] = useState([]);
  const [editUserId, setEditUserId] = useState(null);
  const [editedUser, setEditedUser] = useState({
    name: "",
    email: "",
    role: "",
    isActive: true,
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("Patient"); // Default role
  const [isActive, setIsActive] = useState("true"); // Manage Active/Inactive state

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${url}/user-two`);
      console.log(response);
      setUsers(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const toggleUserStatus = async (
    userId,
    status,
    name,
    email,
    password,
    role
  ) => {
    try {
      const response = await axios.put(`${url}/user-two/update/${userId}`, {
        name: name,
        email: email,
        status: status === "Deactivate" ? "inactive" : "active",
        password: password,
        role: role,
      });
      console.log(response);
      fetchUsers();
    } catch (error) {
      console.log(error);
    }
  };

  const viewUserDetails = (user) => {
    alert(
      `Viewing details for ${user.name} - Email: ${user.email} - Role: ${
        user.role
      } - Status: ${user.isActive ? "Active" : "Inactive"}`
    );
  };

  const updateUser = (userId, userData) => {
    const updatedUsers = users.map((user) => {
      if (user.id === userId) {
        return { ...user, ...userData };
      }
      return user;
    });
    setUsers(updatedUsers);
    setEditUserId(null);
  };

  const editUser = (userId) => {
    setEditUserId(userId);
  };

  const closeModal = () => {
    setEditUserId(null);
  };

  const deleteUser = async (userId) => {
    try {
      const response = await axios.delete(`${url}/user-two/delete/${userId}`);
      console.log(response);
      fetchUsers();
    } catch (error) {
      console.log(error);
    }
  };

  const addUser = async () => {
    try {
      const response = await axios.post(`${url}/user-two/register`, {
        name: name,
        email: email,
        password: password,
        role: role,
      });
      console.log(response);
      fetchUsers();
    } catch (error) {
      console.log(error);
    }
    // setUsers([...users, newUser]);
    // setName("");
    // setEmail("");
    // setRole("User");
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h2>User Management</h2>
      <div>
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div>
        <label>Role:</label>
        <select value={role} onChange={(e) => setRole(e.target.value)}>
          <option value="Pharmacist">pharmacist</option>
          <option value="Admin">Admin</option>
          <option value="HealthAdmin">HealthAdmin</option>
          <option value="HealthcareProvider">HealthcareProvider</option>
          <option value="user">patient</option>
        </select>

        <label>Status:</label>
        <select value={isActive} onChange={(e) => setIsActive(e.target.value)}>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
      <button onClick={addUser}>Add User</button>
      <table
        style={{ width: "80%", margin: "0 auto", borderCollapse: "collapse" }}
      >
        <caption>List of Users</caption>
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f2f2f2",
              }}
            >
              ID
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f2f2f2",
              }}
            >
              Name
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f2f2f2",
              }}
            >
              Email
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f2f2f2",
              }}
            >
              Role
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f2f2f2",
              }}
            >
              Status
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f2f2f2",
              }}
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {user.id}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {user.name}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {user.email}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {user.role}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {user.status === "active" ? "Active" : "Inactive"}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                <button onClick={() => viewUserDetails(user)}>View</button>
                <button onClick={() => editUser(user.id)}>Edit</button>
                <button
                  onClick={() =>
                    toggleUserStatus(
                      user.id,
                      user.status === "active" ? "Deactivate" : "Activate",
                      user.username,
                      user.email,
                      user.password,
                      user.role
                    )
                  }
                >
                  {user.status === "active" ? "Deactivate" : "Activate"}
                </button>
                <button onClick={() => deleteUser(user.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editUserId !== null && (
        <EditUserModal
          user={users.find((user) => user.id === editUserId)}
          setUsers={setUsers}
          closeModal={closeModal}
        />
      )}
    </div>
  );
}

export default UserList;
