// Samyukth challa 1002075109
import React from "react";
import './index.css'
import FacilityTable from "./Table";
import { Grid } from "@mui/material";
export default function index() {
  return (
    <div>
      <div className="container">
        <Grid container px={4}>
          <FacilityTable/>
        </Grid>
      </div>
    </div>
  );
}
