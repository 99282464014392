/*
Thirunagari
Sulakshana
1002190993
*/
import React, { useState } from 'react';
import './Patient.css';
import { FaStethoscope } from 'react-icons/fa';
import { symptomsDummyData } from './mocks'
import axios from 'axios';

const SymptomChecker = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [loading, setLoading] = useState(false);
    
    localStorage.setItem("syptomCheckerKey", '35821b29e9msha9803438ad80fabp14cb8cjsnd1a11fafda85');

    const getSymptomCheckerKey = (backupKey, primaryKey) => {
        const backupValue = localStorage.getItem(backupKey);
        if (backupValue !== null) {
          return backupValue;
        } else {
          const primaryValue = localStorage.getItem(primaryKey);
          return primaryValue !== null ? primaryValue : null;
        }
      }
      

    const handleSearch = async () => {
        
        const key = getSymptomCheckerKey("backupKey","syptomCheckerKey");

        setLoading(true);
        try {

            const normalizedSearch = searchTerm;
            const options = {
                method: 'POST',
                url: 'https://chatgpt-42.p.rapidapi.com/conversationgpt4',
                headers: {
                    'content-type': 'application/json',
                    'X-RapidAPI-Key': `${key}`,
                    'X-RapidAPI-Host': 'chatgpt-42.p.rapidapi.com'
                },
                data: {
                    messages: [
                        {
                            role: 'user',
                            content: `Please give me best possible result for these sypmtoms ${normalizedSearch}`
                        }
                    ],
                    system_prompt: '',
                    temperature: 0.9,
                    top_k: 5,
                    top_p: 0.9,
                    max_tokens: 256,
                    web_access: false
                }
            };
            const response = await axios.request(options);
            setLoading(false);
            return setSearchResult([{ symptom: ` ${searchTerm}`, guidance: response?.data?.result }])
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <div className="patient-section">
            <h2><FaStethoscope />Symptom Checker</h2>
            <p>Please describe any symptoms you are experiencing in the search bar below. Our system will help you find relevant information to assist you further.</p>
            <br />
            <div className="patient-symptomSearchContainer">
                <input type="text" class="patient-symptomSearchBar" placeholder="Search..." value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className='patient-symptomSearchButton' onClick={handleSearch} disabled={searchTerm === '' || loading}>Search</button>
            </div>
            <span className='helperTitles'>Example Keywords: Fever, Cough ...</span>
            {searchResult?.length >= 1 && !loading &&
                <div className='patientCardContainer'>
                    <h2>Symptoms and Guidance</h2>
                    <ul>
                        {searchResult?.map((symptomObj, index) => (
                            <li key={index}>
                                <strong>{symptomObj.symptom}:</strong>
                                <ul>
                                    {symptomObj.guidance.split('\n').map((point, idx) => (
                                        point.trim() && <li key={idx}>{point.trim()}.</li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            }
            {loading && <div className='patientSpinner'></div>}

        </div >
    )
}

export default SymptomChecker