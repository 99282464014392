/*
Thirunagari
Sulakshana
1002190993
*/
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import url from '../../../url';

const Prescription = () => {

    const [prescriptionData, setPrescriptionData] = useState([]);

    const fetchPrescriptionData = async () => {
        try {
            const response = await axios.get(`${url}/prescriptions/patient/${sessionStorage.getItem("userId")}`);
            console.log(response);
            setPrescriptionData(response.data?.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchPrescriptionData();
    }, []);

    return (
        <>
            {prescriptionData?.length == 0 ?
                <div className='prescriptionHelperTitle'>
                    <span className='helperTitles'>At the moment, there are no prescriptions. To add one, simply navigate to the 'Manage Prescriptions' tab</span>
                </div>
                :
                <div className="patientCardContainer">
                    <h2>Ongoing</h2>
                    <hr></hr>
                    {prescriptionData?.map(medication => (
                        <div>
                            <h4>{medication.name}</h4>
                            <p>Dosage: {medication.dosage}</p>
                            <p>Frequency: {medication.frequency}</p>
                            <p>From:{medication.start}  To:{medication.end}</p>
                            <hr></hr>
                        </div>
                    ))}
                </div>
            }
        </>
    );
};

export default Prescription;