/*
Thirunagari
Sulakshana
1002190993
*/
import React, { useEffect, useState } from 'react';
import { Form, Modal, Input } from 'antd';
import axios from 'axios';
import url from '../../../url';
import { FaPlusCircle } from 'react-icons/fa';

const MedicalHistory = () => {
    const [illnessModalVisible, setIllnessModalVisible] = React.useState(false);
    const [surgeryModalVisible, setSurgeryModalVisible] = React.useState(false);
    const [allergyModalVisible, setAllergyModalVisible] = React.useState(false);
    const [familyHistoryModalVisible, setFamilyHistoryModalVisible] = React.useState(false);
    const [illnesForm] = Form.useForm();
    const [surgeryForm] = Form.useForm();
    const [allergyForm] = Form.useForm();
    const [familyHistoryForm] = Form.useForm();
    const user = sessionStorage.getItem('userId');
    const [medicalHistory, setMedicalHistory] = React.useState(null);
    const [loading, isLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const getPatientHealthRecord = async () => {
        try {
            const response = await axios.get(`${url}/patients/heath-record/${user}`);
            isLoading(false)
            const data = response.data;
            setMedicalHistory(data);
        } catch (error) {
            isLoading(false)
            console.log(error);
        }

    }
    useEffect(() => {
        isLoading(true)
        getPatientHealthRecord();
    }, []);

    return (
        <>
            {loading ? <div className='patientSpinner'></div>
                :
                <div className="patientCardContainer">
                    <div className='patientTitles'>
                        <h2>Medical History</h2>
                    </div>
                    <div className='patient-grid'>
                        <div>
                            <h3 className='patientMedicalHistoryAction'>Past Illnesses</h3>
                            <button className='patientMedicalHistoryButtons' onClick={() => setIllnessModalVisible(true)}><i className='fa-patient-icon'><FaPlusCircle /> </i></button>
                            <ul>
                                {medicalHistory?.illnesses.map((illness, index) => (
                                    <li key={index}>{illness.illness}</li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h3 className='patientMedicalHistoryAction'>Surgeries</h3>
                            <button className='patientMedicalHistoryButtons' onClick={() => setSurgeryModalVisible(true)}><i className='fa-patient-icon'><FaPlusCircle /> </i></button>
                            <ul>
                                {medicalHistory?.surgeries.map((surgery, index) => (
                                    <li key={index}>{surgery?.surgery}</li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h3 className='patientMedicalHistoryAction'>Allergies</h3>
                            <button className='patientMedicalHistoryButtons' onClick={() => setAllergyModalVisible(true)}><i className='fa-patient-icon'><FaPlusCircle /> </i></button>
                            <ul>
                                {medicalHistory?.allergies.map((allergy, index) => (
                                    <li key={index}>{allergy?.allergy}</li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h3 className='patientMedicalHistoryAction'>Family History</h3>
                            <button className='patientMedicalHistoryButtons' onClick={() => setFamilyHistoryModalVisible(true)}><i className='fa-patient-icon'><FaPlusCircle /> </i></button>
                            <ul>
                                {medicalHistory?.familyHistories.map((familyHistory, index) => (
                                    <li key={index}>{familyHistory.familyHistory}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <Modal title="Add your past illness " open={illnessModalVisible} onOk={() => illnesForm.submit()}
                        onCancel={() => setIllnessModalVisible(false)} okButtonProps={{ disabled: submitting }}>
                        <Form form={illnesForm} layout="vertical" onFinish={
                            async (values) => {
                                try {
                                    setSubmitting(true);
                                    values.patient = user;
                                    await axios.post(`${url}/patients/heath-record/illness`, values);
                                    await getPatientHealthRecord();
                                    setSubmitting(false);
                                    illnesForm.resetFields();
                                    setIllnessModalVisible(false);
                                } catch (error) {
                                    console.log(error);
                                    window.alert("Failed to add illness");
                                }
                            }
                        }>
                            <Form.Item name="illness" label="Illness" required={true} rules={[{ required: true, message: 'Please enter your Illness' }]}>
                                <Input autoComplete='off' />
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal title="Add your past surgeries" open={surgeryModalVisible} onOk={() => surgeryForm.submit()}
                        onCancel={() => setSurgeryModalVisible(false)} okButtonProps={{ disabled: submitting }}>
                        <Form form={surgeryForm} layout="vertical" onFinish={
                            async (values) => {
                                try {
                                    setSubmitting(true);
                                    values.patient = user;
                                    await axios.post(`${url}/patients/heath-record/surgery`, values);
                                    await getPatientHealthRecord();
                                    surgeryForm.resetFields();
                                    setSubmitting(false);
                                    setSurgeryModalVisible(false);
                                } catch (error) {
                                    console.log(error);
                                    window.alert("Failed to add surgery");
                                }
                            }
                        }>
                            <Form.Item name="surgery" label="Surgery" required={true} rules={[{ required: true, message: 'Please enter your surgery details' }]}>
                                <Input autoComplete='off' />
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal title="Add your allergies" open={allergyModalVisible} onOk={() => allergyForm.submit()}
                        onCancel={() => setAllergyModalVisible(false)} okButtonProps={{ disabled: submitting }}>
                        <Form form={allergyForm} layout="vertical" onFinish={
                            async (values) => {
                                try {
                                    setSubmitting(true);
                                    values.patient = user;
                                    await axios.post(`${url}/patients/heath-record/allergy`, values);
                                    await getPatientHealthRecord();
                                    setSubmitting(false);
                                    allergyForm.resetFields();
                                    setAllergyModalVisible(false);
                                } catch (error) {
                                    console.log(error);
                                    window.alert("Failed to add allergy");
                                }
                            }
                        }>
                            <Form.Item name="allergy" label="Allergy" required={true} rules={[{ required: true, message: 'Please enter your allergy details' }]}>
                                <Input autoComplete='off' />
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal title="Add your family history" open={familyHistoryModalVisible} onOk={() => familyHistoryForm.submit()}
                        onCancel={() => setFamilyHistoryModalVisible(false)} okButtonProps={{ disabled: submitting }}>
                        <Form form={familyHistoryForm} layout="vertical" onFinish={
                            async (values) => {
                                try {
                                    setSubmitting(true);
                                    values.patient = user;
                                    await axios.post(`${url}/patients/heath-record/family-history`, values);
                                    await getPatientHealthRecord();
                                    familyHistoryForm.resetFields();
                                    setSubmitting(false);
                                    setFamilyHistoryModalVisible(false);
                                } catch (error) {
                                    console.log(error);
                                    window.alert("Failed to add family history");
                                }
                            }
                        }>
                            <Form.Item name="familyHistory" label="Family History" required={true} rules={[{ required: true, message: 'Please enter your family history' }]}>
                                <Input autoComplete='off' />
                            </Form.Item>

                        </Form>
                    </Modal>
                </div>
            }
        </>
    );
};


export default MedicalHistory;