//Samyukth challa 1002075109
// Modify the PrivateRoute component to work as an element prop
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { roleAccess } from './Constants/Users'; // Make sure the path is correct

const PrivateRoute = ({ children }) => {
  const { pathname } = useLocation();
  const role = sessionStorage.getItem('userRole');
  const hasAccess = role && roleAccess?.[role]?.includes(pathname);
  console.log(pathname, roleAccess?.[role], hasAccess);
  // const hasAccess = true;

  if (hasAccess) {
    return <>
     
      {children}
    </>;
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
