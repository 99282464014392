/*Sangram Yathati- 1002168539*/
// src/components/HealthcareProvider/HealthcareProvider.js
import React from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import "./HealthcareProvider.css";
import { FaUser, FaCog, FaSignOutAlt, FaBars } from "react-icons/fa";

function HealthcareProvider() {
  const navigate = useNavigate(); // Initialize navigate

  // Function to handle navigation to the E-Prescriptions page
  const handleCreatePrescription = () => {
    navigate("/e-prescriptions"); // Use navigate to go to 'e-prescriptions'
  };
  // Function to handle navigation to the analytics-dashboard page
  const handleCreateAnalyticsDashboard = () => {
    navigate("/analytics-dashboard"); // Use navigate to go to 'analytics-dashboard'
  };
  // Function to handle navigation to the patient-health-records page
  const handleCreatePatientHealthRecords = () => {
    navigate("/patient-health-records"); // Use navigate to go to 'patient-health-records'
  };
  // Function to handle navigation to the professional-collaboration page
  const handleCreateProfessionalCollaboration = () => {
    navigate("/professional-collaboration"); // Use navigate to go to 'professional-collaboration'
  };
  // Function to handle navigation to the handleCreateAppointmenManagement page
  const handleCreateAppointmenManagement = () => {
    navigate("/appointment-management"); // Use navigate to go to 'handleCreateAppointmenManagement'
  };
  // Function to handle navigation to the secure-messaging page
  const handleCreateSecureMessaging = () => {
    navigate("/secure-messaging"); // Use navigate to go to 'secure-messaging'
  };
  const handleLogout = () => {
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("userRole");
    navigate("/login");
  };
  return (
    <div className="provider-container">
      {/* Top Bar */}
      <div className="top-bar">
        <h1 className="provider-heading">Welcome Healthcare Provider!</h1>
        <div className="top-bar-buttons">
          <Link to="/http://Vsa5321.uta.cloud"> Blog</Link>
        </div>
        <div className="top-bar-buttons">
          <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={handleLogout}
          >
            <FaSignOutAlt className="icon" /> Logout
          </div>
        </div>
      </div>
      <div className="healthcare-provider-content">
        <div className="provider-grid">
          <div className="provider-section">
            <h2>E-Prescriptions</h2>
            <p>
              Healthcare providers can electronically prescribe medications,
              improving prescription accuracy and reducing paper-based
              processes.
            </p>
            {/* Link to the E-Prescriptions page */}
            <button
              className="provider-button"
              onClick={handleCreatePrescription}
            >
              Create E-Prescription
            </button>
          </div>

          <div className="provider-section">
            <h2>Appointment Management</h2>
            <p>
              Access to a centralized system for viewing and managing
              appointments scheduled by patients, streamlining the appointment
              booking process.
            </p>
            <button
              className="provider-button"
              onClick={handleCreateAppointmenManagement}
            >
              Manage Appointments
            </button>
          </div>

          <div className="provider-section">
            <h2>Access to Patient Health Records</h2>
            <p>
              Healthcare providers can view and update patient health records,
              facilitating personalized and informed healthcare delivery.
            </p>
            <button
              className="provider-button"
              onClick={handleCreatePatientHealthRecords}
            >
              Access Patient Records
            </button>
          </div>

          <div className="provider-section">
            <h2>Secure Messaging</h2>
            <p>
              Encrypted messaging allows secure communication between healthcare
              providers and patients, ensuring privacy and confidentiality.
            </p>
            <button
              className="provider-button"
              onClick={handleCreateSecureMessaging}
            >
              Send Secure Message
            </button>
          </div>

          <div className="provider-section">
            <h2>Professional Collaboration</h2>
            <p>
              Healthcare providers can participate in health forums to
              collaborate with peers, share insights, and contribute to
              community knowledge.
            </p>
            <button
              className="provider-button"
              onClick={handleCreateProfessionalCollaboration}
            >
              Collaborate with Peers
            </button>
          </div>

          <div className="provider-section">
            <h2>Analytics Dashboard</h2>
            <p>
              Access to data-driven insights and analytics dashboards to monitor
              patient health trends, enabling more proactive and personalized
              care.
            </p>
            <button
              className="provider-button"
              onClick={handleCreateAnalyticsDashboard}
            >
              View Analytics Dashboard
            </button>
          </div>

          <div className="admin-section">
            <h2>Chat</h2>
            <p>For communications</p>
            <button className="admin-button" onClick={() => navigate("/chat")}>
              Community Chat
            </button>{" "}
            {/* Button to navigate to the Report Generation page */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HealthcareProvider;
