/*
Thirunagari
Sulakshana
1002190993
*/
import React, { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { Form, Modal, Input } from 'antd';
import axios from 'axios';
import url from '../../../url';
import { FaPlusCircle } from 'react-icons/fa';

const Exercise = () => {
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const user = sessionStorage.getItem('userId');

    const [exerciseData, setExerciseData] = useState([]);
    const getExerciseData = async () => {
        try {
            const response = await axios.get(`${url}/patients/heath-record/excercise-data/${user}`);
            const data = response.data?.excerciseData;
            setExerciseData(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getExerciseData();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${url}/patients/heath-record/excercise-data/${id}`);
            getExerciseData();
        } catch (error) {
            console.log(error);
        }

    };

    return (
        <div className='patient-table'>
            <h2 className='patientTitles'>Exercise Data</h2>
            <button className='phrFloatButton' onClick={() => setVisible(true)}><i className='fa-patient-icon'><FaPlusCircle /> </i>Add Exercise Data</button>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Activity</th>
                        <th>Duration</th>
                        <th>Calories Burned</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {exerciseData.map((record, index) => (
                        <tr key={index}>
                            <td>{record.date}</td>
                            <td>{record.activity}</td>
                            <td>{record.duration}</td>
                            <td>{record.calorieBurned}</td>
                            <td><span className='patient-span' onClick={() => handleDelete(record.id)}><i className='fa-patient-icon'><FaTrash /></i></span> </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal title="Add Exercise Data" open={visible} onOk={() => { form.submit() }} onCancel={() => { setVisible(false) }} >
                <Form form={form} onFinish={
                    async (values) => {
                        try {
                            values.patient = sessionStorage.getItem('userId');
                            await axios.post(`${url}/patients/heath-record/excercise-data`, values);
                            form.resetFields();
                            getExerciseData();

                        } catch (error) {
                        }
                        setVisible(false);
                    }
                }>
                    <Form.Item name="date" label="Date" required={true} rules={[{ required: true, message: 'Please enter date' }]}>
                        <Input type="date" autoComplete='off'/>
                    </Form.Item>
                    <Form.Item name="activity" label="Activity" required={true}  rules={[{ required: true, message: 'Please enter date' }]}>
                        <Input type="text" autoComplete='off'/>
                    </Form.Item>
                    <Form.Item name="duration" label="Duration" required={true}  rules={[{ required: true, message: 'Please enter date' }]}>
                        <Input type="text" autoComplete='off'/>
                    </Form.Item>
                    <Form.Item name="caloriesBurned" label="Calories Burned" required={true}  rules={[{ required: true, message: 'Please enter date' }]}>
                        <Input type="text" autoComplete='off'/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Exercise;