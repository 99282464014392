/*
  BOMMALI SNEHASHISH 
  1002127072
*/
// Importing React library and necessary components
import React from "react";
import { useNavigate } from "react-router-dom"; // Importing useNavigate hook from react-router-dom
import { Link } from "react-router-dom"; // Importing Link component from react-router-dom
import { FaUser, FaCog, FaSignOutAlt, FaBars } from "react-icons/fa"; // Importing Font Awesome icons
import "./Administrator.css"; // Importing styles for the Administrator component

// Functional component definition for Administrator
function Administrator() {
  const navigate = useNavigate(); // Initializing the navigate function using the useNavigate hook
  const handleLogout = () => {
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("userRole");
    navigate("/login");
  };
  // Rendering the Administrator component
  return (
    <div className="admin-container">
      {/* Top Bar */}
      <div className="top-bar">
        <h1 className="admin-heading">Welcome Administrator!</h1>

        <div className="top-bar-buttons">
          <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={handleLogout}
          >
            <FaSignOutAlt className="icon" /> Logout
          </div>
          {/* Link to the Logout page */}
        </div>
      </div>

      {/* Administrator Content */}
      <div className="admin-content">
        <div className="admin-grid">
          {/* Section for User Management */}
          <div className="admin-section">
            <h2>User Management</h2>
            <p>
              Admins handle user accounts, overseeing registration, activation,
              and deactivation of accounts to ensure the integrity of the user
              base.
            </p>
            <button
              className="admin-button"
              onClick={() => navigate("/user-management")}
            >
              Manage Users
            </button>{" "}
            {/* Button to navigate to the User Management page */}
          </div>

          {/* Section for Healthcare Provider Management */}
          <div className="admin-section">
            <h2>Healthcare Provider Management</h2>
            <p>
              Admins manage healthcare provider profiles, ensuring completeness
              and accuracy of information.
            </p>
            <button
              className="admin-button"
              onClick={() => navigate("/provider-management")}
            >
              Manage Healthcare Providers
            </button>{" "}
            {/* Button to navigate to the Healthcare Provider Management page */}
          </div>

          {/* Section for System Configuration */}
          <div className="admin-section">
            <h2>System Configuration</h2>
            <p>
              Admins handle system-level configurations, ensuring smooth
              operation, and integrating new features or services.
            </p>
            <button
              className="admin-button"
              onClick={() => navigate("/system-configuration")}
            >
              Configure System
            </button>{" "}
            {/* Button to navigate to the System Configuration page */}
          </div>

          <div className="admin-section">
            <h2>Report Generation</h2>
            <p>
              Admins generate reports on user activity, system performance, and
              health trends for analysis and improvement, supporting informed
              decision-making.
            </p>
            <button
              onClick={() => navigate("/report-generation")}
              className="admin-button"
            >
              Generate Reports
            </button>
          </div>

          <div className="admin-section">
            <h2>Chat</h2>
            <p>For communications</p>
            <button className="admin-button" onClick={() => navigate("/chat")}>
              Community Chat
            </button>{" "}
            {/* Button to navigate to the Report Generation page */}
          </div>
        </div>
      </div>
    </div>
  );
}

// Exporting the Administrator component as the default export
export default Administrator;
