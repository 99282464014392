/*
  BOMMALI SNEHASHISH 
  1002127072
*/
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Auth.css"; // Check the correct relative path
import { FormHelperText, TextField, Button, Grid, Paper } from "@mui/material";
import { Users, entryRoute } from "../../../Constants/Users";
import axios from "axios";
import url from "../../../url";

function Login() {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(false);
  const validate = () => {
    const formErrors = {};
    const { username, password } = formData;
    if (!username || username.trim() === "") {
      formErrors.username = "Username cannot be empty";
    }

    if (!password || password.trim() === "") {
      formErrors.password = "Password cannot be empty";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length > 0 ? false : true;
  };
  const handleSubmit = async (e) => {
    const isValid = validate();
    const { username, password } = formData;
    if (!isValid) {
      return;
    } else {
      // const validCreds = Users.find(
      //   (user) => username === user.username && user.password === password
      // );
      // console.log(validCreds);
      let validCreds = null;
      try {
        const response = await axios.post(`${url}/user-two/login`, {
          email: username,
          password: password,
        });
        console.log(response);
        validCreds = response.data.data;
      } catch (error) {
        console.log(error);
      }
      if (validCreds) {
        sessionStorage.setItem("name", validCreds.name);
        sessionStorage.setItem("userRole", validCreds.role);
        sessionStorage.setItem("userId", validCreds.id);
        localStorage.setItem("user", validCreds);

        const initRoute = entryRoute[validCreds.role];
        initRoute && navigate(initRoute);
      } else {
        setLoginError(true);
      }
    }
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <div className="auth-container">
      <Paper>
        <div className="auth-box">
          <h2>Login</h2>
          <Grid gap={3} display={"flex"} flexDirection={"column"}>
            <TextField
              onChange={handleChange}
              name="username"
              error={errors?.username?.length > 0}
              helperText={errors?.username}
              fullWidth
              required
              type="text"
              id="username"
              placeholder="Enter your Email"
            />
            <TextField
              onChange={handleChange}
              name="password"
              error={errors?.password?.length > 0}
              helperText={errors?.password}
              fullWidth
              required
              type="password"
              id="password"
              placeholder="Enter your Password"
            />

            {loginError && (
              <FormHelperText variant="filled" error>
                username/password is incorrect
              </FormHelperText>
            )}
            <Button
              variant="contained"
              className="btn btn-success w-100"
              onClick={handleSubmit}
            >
              Login
            </Button>
          </Grid>
          <p>
            Don't have an account? <Link to="/signup">Signup here</Link>.
          </p>
        </div>
      </Paper>
    </div>
  );
}

export default Login;
