/*
  BOMMALI SNEHASHISH 
  1002127072
*/
import React, { useState } from 'react';
import './Services.css'; // Create Services.css for styling
import Footer from './Footer';

function Services() {
  const [expandedService, setExpandedService] = useState(null);

  const handleReadMore = (index) => {
    setExpandedService(expandedService === index ? null : index);
  };

  const services = [
    {
      title: 'Symptom Checker',
      description: 'Description: Allows users to input their symptoms and receive potential diagnoses or recommendations.',
    },
    {
      title: 'Medication Reminders',
      description: 'Description: Enables users to set reminders for taking medications at specific times, ensuring they adhere to their prescribed treatment plans.',
    },
    {
      title: 'Personal Health Records Management',
      description: 'Description: Provides users with a platform to store and manage their personal health records, including medical history, test results, and vaccination records.',
    },
    {
      title: 'Facility Management',
      description: 'Description: Allows healthcare facility administrators to manage various aspects of their facilities, including scheduling, resource allocation, and inventory management.',
    },
    {
      title: 'Staff Coordination',
      description: 'Description: Facilitates coordination among healthcare staff members, enabling efficient communication, task assignment, and collaboration on patient care.',
    },
    {
      title: 'Compliance Oversight',
      description: 'Description: Helps healthcare organizations ensure compliance with regulatory requirements and industry standards, including data security, privacy laws, and accreditation criteria.',
    },
    {
      title: 'Incident Response',
      description: 'Description: Provides tools and protocols for managing and responding to incidents such as medical emergencies, security breaches, or adverse events effectively.',
    },
    {
      title: 'E-Prescriptions',
      description: 'Description: Enables healthcare providers to electronically generate and transmit prescriptions to pharmacies, streamlining the prescription process and reducing errors.',
    },
    {
      title: 'Appointment Management',
      description: 'Description: Allows patients to schedule, reschedule, or cancel appointments with healthcare providers online, improving accessibility and reducing no-show rates.',
    },
    {
      title: 'Secure Messaging',
      description: 'Description: Offers a secure communication channel for healthcare providers and patients to exchange messages, share information, and discuss treatment plans confidentially.',
    },
    {
      title: 'Professional Collaboration',
      description: 'Description: Facilitates collaboration and information sharing among healthcare professionals, fostering interdisciplinary teamwork and enhancing patient care outcomes.',
    },
    {
      title: 'Analytics Dashboard',
      description: 'Description: Provides healthcare organizations with actionable insights and data visualization tools to analyze trends, track performance metrics, and make informed decisions.',
    },
  ];

  return (
    <div className="services-container">
      <header className="services-header">
        <h1>Our Services</h1>
      </header>
      <div className="services-content">
        {services.map((service, index) => (
          <div className="service" key={index}>
            <h2>{service.title}</h2>
            <p>{expandedService === index ? service.description : service.description.slice(0, 75) + '...'}</p>
            <button className="read-more-btn" onClick={() => handleReadMore(index)}>
              {expandedService === index ? 'Read less' : 'Read more'}
            </button>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default Services;
