import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import url from "../../../url";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Grid,
  IconButton,
  FormHelperText,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import createData from "./data";
import axios from "axios";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "location", label: "location", minWidth: 100 },
  {
    id: "service",
    label: "Service",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "startTime",
    label: "start time",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "endTime",
    label: "end time",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

export default function StickyHeadTable() {
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedRow, setSelectdRow] = useState({});
  const [editForm, setShowEditForm] = React.useState(false);
  const [isEdit, setIsEdit] = useState(-1);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    service: "",
    status: "active",
    startTime: "",
    endTime: "",
  });

  const fetchFacilityData = async () => {
    try {
      const response = await axios.get(`${url}/facilities`);
      console.log(response);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchFacilityData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClose = () => {
    setShowEditForm(false);
    setFormData({
      name: "",
      location: "",
      service: "",
      status: "active",
      startTime: "",
      endTime: "",
    });
    setIsEdit(-1);
  };
  const validate = () => {
    const validationErrors = {};
    if (!formData.name) {
      validationErrors.name = "Name is required";
    }
    if (!formData.location) {
      validationErrors.location = "Location is required";
    }
    if (!formData.service) {
      validationErrors.service = "Service is required";
    }
    if (!formData.startTime) {
      validationErrors.startTime = "Start time is required";
    }
    if (!formData.endTime) {
      validationErrors.endTime = "End time is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return false;
    } else {
      return true;
    }
  };
  const handleSubmit = async (event) => {
    const isValid = validate();
    if (!isValid) return;
    if (isEdit > 0) {
      const newData = [...data];
      newData[isEdit - 1] = { ...formData, id: isEdit };
      try {
        const response = await axios.put(`${url}/facilities/${isEdit}`, {
          name: formData.name,
          location: formData.location,
          service: formData.service,
          status: formData.status,
          startTime: formData.startTime,
          endTime: formData.endTime,
        });
        console.log(response);
        fetchFacilityData();
      } catch (error) {
        console.log(error);
      }
    } else {
      const newData = [
        {
          id: data.length + 1,
          ...formData,
        },
        ...data,
      ];
      try {
        const response = await axios.post(`${url}/facilities`, {
          ...formData,
        });
        console.log(response);
        fetchFacilityData();
      } catch (error) {
        console.log(error);
      }
      // setData(newData);
    }
    event.preventDefault();
    console.log(formData);
    handleClose();
    setIsEdit(-1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleEdit = (item) => {
    setIsEdit(item.id);
    setSelectdRow(item);
    setFormData(item);
    setShowEditForm(true);
  };
  const handleAdd = () => {
    setShowEditForm(true);
    setFormData({
      name: "",
      location: "",
      service: "",
      status: "active",
      startTime: "",
      endTime: "",
    });
  };

  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"flex-end"}
      alignItems={"flex-end"}
    >
      <Grid
        item
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"flex-end"}
        p={2}
      >
        <Button onClick={handleAdd} variant="contained">
          Add Facility
        </Button>
      </Grid>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table stickyHeader aria-label="sticky table" sx={{ border: "none" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth, border: "none" }}
                    align="center"
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.id == "status") {
                          return (
                            <TableCell key={column.id} align="center">
                              <Chip
                                sx={{ width: 100 }}
                                label={value}
                                variant="outlined"
                                color={value == "active" ? "success" : "error"}
                              />
                            </TableCell>
                          );
                        }
                        if (column.id !== "action") {
                          return (
                            <TableCell key={column.id} align="center">
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={column.id} align="center">
                              <Button
                                variant="text"
                                onClick={() => {
                                  handleEdit(row);
                                }}
                              >
                                Edit
                              </Button>
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog open={editForm} onClose={handleClose}>
        <DialogTitle>
          Form
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="name"
                  label="Name"
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="location"
                  label="Location"
                  value={formData.location}
                  onChange={handleChange}
                  error={!!errors.location}
                  helperText={errors.location}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth error={!!errors.service}>
                  <InputLabel>Service</InputLabel>
                  <Select
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                  >
                    <MenuItem value="service1">Service 1</MenuItem>
                    <MenuItem value="service2">Service 2</MenuItem>
                    <MenuItem value="service3">Service 3</MenuItem>
                  </Select>
                  {errors.service && (
                    <FormHelperText>{errors.service}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="time"
                  name="startTime"
                  label="Start Time"
                  value={formData.startTime}
                  onChange={handleChange}
                  error={!!errors.startTime}
                  helperText={errors.startTime}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="time"
                  name="endTime"
                  label="End Time"
                  value={formData.endTime}
                  onChange={handleChange}
                  error={!!errors.endTime}
                  helperText={errors.endTime}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="deactivate">Deactivate</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
