/*
Thirunagari
Sulakshana
1002190993
*/
import React, { useEffect, useState } from 'react';
import './Patient.css';
import { FaPills } from 'react-icons/fa';
import axios from 'axios';
import url from '../../url';

const MedicationReminders = () => {

    const [timeLeft, setTimeLeft] = useState({});
    const [currentReminders, setCurrentReminders] = useState([]);
    const [loading, setLoading] = useState(false);

    const calculateTimeLeft = (medication) => {
        const now = new Date();
        const [hours, minutes] = medication.time.split(':');
        const medicationTime = new Date(now);
        medicationTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);

        let difference = medicationTime - now;

        if (difference > 0) {
            const hoursLeft = Math.floor(difference / (60 * 60 * 1000));
            const minutesLeft = Math.floor((difference % (60 * 60 * 1000)) / (60 * 1000));
            const secondsLeft = Math.floor((difference % (60 * 1000)) / 1000);

            return { hours: hoursLeft, minutes: minutesLeft, seconds: secondsLeft };
        }
        else { return 'Past Due' }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            const newTimeLeft = {};
            currentReminders.forEach(medication => {
                newTimeLeft[medication.id] = calculateTimeLeft(medication);
            });
            setTimeLeft(newTimeLeft);
        }, 1000);
        return () => clearInterval(timer);
    }, [currentReminders]);

    const fetchReminders = async () => {
        try {
            const response = await axios.get(`${url}/patients/medication-reminder/${sessionStorage.getItem("userId")}`);
            setLoading(false)
            setCurrentReminders(response.data?.medicationReminders);
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    useEffect(() => {
        setLoading(true)
        fetchReminders();
    }, []);

    return (
        <div className="patient-section">
            <h2><FaPills /> Medication Reminders</h2>
            {currentReminders?.length == 0 ?
                <span className='helperTitles'>At the moment, there are no medication reminders set up. To schedule one, simply navigate to the 'Manage Prescriptions' tab</span>
                :
                <>
                    {currentReminders.map(medication => (
                        <div key={medication.id} className="patientCardContainer">
                            <h3>{medication.name}</h3>
                            <p>Dosage: {medication.dosage}</p>
                            <p>Scheduled Time: {medication.time}</p>
                            <p>Time Left: {timeLeft[medication.id] && (
                                `${timeLeft[medication.id] != 'Past Due' ? `${timeLeft[medication.id].hours}h ${timeLeft[medication.id].minutes}m ${timeLeft[medication.id].seconds}s` : 'Past Due'}`
                            )}</p>
                        </div>
                    ))}
                </>
            }
            {loading && <div className='patientSpinner'></div>}
        </div>
    );
};

export default MedicationReminders