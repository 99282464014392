/* Samyukth challa 1002075109 */
// Array containing user objects with usernames, passwords, and roles.
export const Users = [
  { username: "user1", password: "password1", role: "patient" ,id:1},
  { username: "user2", password: "password2", role: "pharmacist",id:2 },
  { username: "user3", password: "password3", role: "admin" ,id:3},
  { username: "user4", password: "password4", role: "healthAdmin",id:4 },
  { username: "user5", password: "password5", role: "healthCareProvider",id:5 },
  // Add more users as necessary
];

// Object containing role-based access routes.
export const roleAccess = {
  HealthAdmin: [
    "/healthcare-administrator-dashboard",
    "/healthadmin/facility",
    "/healthadmin/staff",
    "/healthadmin/incident",
    "/healthadmin/compliance",
  ],
  patient: [
    "/patient-dashboard",
    // Include additional routes accessible by patients
  ],
  Pharmacist: [
    "/pharmacist-dashboard",
    // Include additional routes accessible by pharmacists
  ],
  admin: [
    "/administrator-dashboard",
    "/user-management",
    "/provider-management",
    "/system-configuration",
    "/data-monitoring",
    "/report-generation",
    // Include additional routes accessible by admins
  ],
  HealthcareProvider: [
    "/healthcare-provider-dashboard",
    "/e-prescriptions",
    "/appointment-management",
    "/patient-health-records",
    "/professional-collaboration",
    "/secure-messaging",
    "/analytics-dashboard",
    // Include additional routes accessible by doctors
  ],
  // Add other roles as necessary
};

// Object containing initial routes for each user role.
export const entryRoute = {
  HealthAdmin: "/healthcare-administrator-dashboard",
  patient: "/patient-dashboard",
  Pharmacist: "/pharmacist-dashboard",
  admin: "/administrator-dashboard",
  HealthcareProvider: "/healthcare-provider-dashboard",
  // Define initial routes for other user roles
};
