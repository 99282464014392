// Samyukth challa 1002075109
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import url from "../../../url";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Grid,
  IconButton,
  FormHelperText,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import createData from "./data";
import axios from "axios";

const columns = [
  { id: "title", label: "Title", minWidth: 170 },
  { id: "location", label: "location", minWidth: 100 },
  {
    id: "description",
    label: "description",
    minWidth: 170,
  },
  
  {
    id: "date",
    label: "date",
    minWidth: 170,
    align: "right",
  },
  {
    id: "time",
    label: "time",
    minWidth: 170,
    align: "right",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

export default function StickyHeadTable() {
  const [data, setData] = useState(createData());
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedRow, setSelectdRow] = useState({});
  const [editForm, setShowEditForm] = React.useState(false);
  const [isEdit, setIsEdit] = useState(-1);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    services: "",
    operationalStatus: "active",
    startTime: "",
    endTime: "",
  });

  const fetchIncidentReportData = async () => {
    try {
      const response = await axios.get(`${url}/incidents`);
      console.log(response);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchIncidentReportData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClose = () => {
    setShowEditForm(false);
    setFormData({
      title: "",
      location: "",
      description: "",
      date: "",
      time: "",
    });
    setIsEdit(-1);
  };
  const validate = () => {
    const validationErrors = {};
    if (!formData.title) {
      validationErrors.title = "Name is required";
    }
    if (!formData.location) {
      validationErrors.location = "Location is required";
    }
    if (!formData.description) {
      validationErrors.description = "Service is required";
    }
    if (!formData.date) {
      validationErrors.date = "Start time is required";
    }
    if (!formData.time) {
      validationErrors.time = "End time is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return false;
    } else {
      return true;
    }
  };
  const handleSubmit = async (event) => {
    const isValid = validate();
    if (!isValid) return;
    if (isEdit > 0) {
      try {
        const response = await axios.put(`${url}/incidents/${isEdit}`, {
          ...formData,
        });
        console.log(response);
        handleClose();
        setIsEdit(-1);
        fetchIncidentReportData();
      } catch (error) {
        console.log(error);
      }
      // const newData = [...data];
      // newData[isEdit - 1] = { ...formData, id: isEdit };
      // setData(newData);
    } else {
      try {
        const response = await axios.post(`${url}/incidents`, {
          ...formData,
        });
        console.log(response);
        handleClose();
        setIsEdit(-1);
        fetchIncidentReportData();
      } catch (error) {
        console.log(error);
      }
      // const newData = [
      //   {
      //     id: data.length + 1,
      //     ...formData,
      //   },
      //   ...data,
      // ];
      // setData(newData);
    }
    event.preventDefault();
    //console.log(formData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleEdit = (item) => {
    setIsEdit(item.id);
    setSelectdRow(item);
    setFormData(item);
    setShowEditForm(true);
  };
  const handleAdd = () => {
    setShowEditForm(true);
    setFormData({
      title: "",
      location: "",
      description: "",
      date: "",
      time: "",
    });
  };

  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"flex-end"}
      alignItems={"flex-end"}
    >
      <Grid
        item
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"flex-end"}
        p={2}
      >
        <Button onClick={handleAdd} variant="contained">
          {" "}
          Report an incident
        </Button>
      </Grid>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table stickyHeader aria-label="sticky table" sx={{ border: "none" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth, border: "none" }}
                    align="center"
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.id == "operationalStatus") {
                          return (
                            <TableCell key={column.id} align="center">
                              <Chip
                                sx={{ width: 100 }}
                                label={value}
                                variant="outlined"
                                color={value == "active" ? "success" : "error"}
                              />
                            </TableCell>
                          );
                        }
                        if (column.id !== "action") {
                          return (
                            <TableCell key={column.id} align="center">
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={column.id} align="center">
                              <Button
                                variant="text"
                                onClick={() => {
                                  handleEdit(row);
                                }}
                              >
                                Edit
                              </Button>
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog open={editForm} onClose={handleClose}>
        <DialogTitle>
          Form
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="title"
                  label="Title"
                  value={formData.title}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="location"
                  label="Location"
                  value={formData.location}
                  onChange={handleChange}
                  error={!!errors.location}
                  helperText={errors.location}
                />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  fullWidth
                  name="description"
                  label="Description"
                  value={formData.description}
                  onChange={handleChange}
                  error={!!errors.description}
                  helperText={errors.description}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="date"
                  name="date"
                  label="Date"
                  value={formData.date}
                  onChange={handleChange}
                  error={!!errors.date}
                  helperText={errors.date}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="time"
                  name="time"
                  label="Time"
                  value={formData.time}
                  onChange={handleChange}
                  error={!!errors.time}
                  helperText={errors.time}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
