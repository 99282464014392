/*Sangram Yathati- 1002168539*/
// AnalyticsDashboard.js
import React, { useState } from 'react';
import './AnalyticsDashboard.css';
import { Line } from 'react-chartjs-2';

const AnalyticsDashboard = () => {
    // State to hold multiple patients' health trends data
    const [patientsData, setPatientsData] = useState([
        {
            id: 1,
            name: "Patient A",
            healthTrends: [
                { date: '2024-03-01', healthScore: 70 },
                { date: '2024-03-02', healthScore: 72 },
                { date: '2024-03-03', healthScore: 68 },
                // ... more data
            ]
        },
        {
            id: 2,
            name: "Patient B",
            healthTrends: [
                { date: '2024-03-01', healthScore: 60 },
                { date: '2024-03-02', healthScore: 62 },
                { date: '2024-03-03', healthScore: 65 },
                // ... more data
            ]
        },
        // ... add more patients as needed
    ]);

    // State to hold the currently selected patient ID
    const [selectedPatientId, setSelectedPatientId] = useState(patientsData[0].id);

    // Function to handle patient selection
    const handlePatientSelection = (id) => {
        setSelectedPatientId(id);
    };

    // Get the currently selected patient's data
    const selectedPatientData = patientsData.find(patient => patient.id === selectedPatientId);

    // Prepare the data for Chart.js
    const data = {
        labels: selectedPatientData.healthTrends.map(trend => trend.date),
        datasets: [
            {
                label: `Health Score - ${selectedPatientData.name}`,
                data: selectedPatientData.healthTrends.map(trend => trend.healthScore),
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
            }
        ]
    };

    return (
        <div className='dashboard-container'>
            <h2>Analytics Dashboard</h2>
            <div className='patient-selector'>
                {patientsData.map(patient => (
                    <button key={patient.id} onClick={() => handlePatientSelection(patient.id)}>
                        {patient.name}
                    </button>
                ))}
            </div>
            <div className='chart-container'>
                <Line data={data} />
            </div>
        </div>
    );
};

export default AnalyticsDashboard;
