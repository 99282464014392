// Samyukth challa 1002075109

const createData = () => {
    const dummyData = [];
    const services = ['service1', 'service2', 'service3'];
    const startHour = Math.floor(Math.random() * 12);
    const endHour = startHour + Math.floor(Math.random() * 4) + 1; 
    for (let i = 1; i <= 10; i++) {
        dummyData.push({
            id: i,
            name: `Service ${i}`,
            location: `Location ${i}`,
            services: services[Math.floor(Math.random() * services.length)],
            operationalStatus: i % 2 === 0 ? 'active' : 'inactive',
            startTime: `${startHour < 10 ? '0' + startHour : startHour}:00`,
            endTime: `${endHour < 10 ? '0' + endHour : endHour}:00`
        });
    }

    return dummyData;
};

export default createData;
