/*
Thirunagari
Sulakshana
1002190993
*/
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaUser,
  FaCog,
  FaSignOutAlt,
  FaBars,
  FaStethoscope,
  FaPills,
  FaCapsules,
  FaIdCard,
  FaCalendarPlus,
  FaHandshake,
} from "react-icons/fa";
import "./Patient.css";
import SymptomChecker from "./SymptomChecker.js";
import MedicationReminders from "./MedicationReminders.js";
import PersonalHealthRecords from "./PersonalHealthRecords.js";
import AppointmentManagement from "./AppointmentManagement.js";
import CommunityInteraction from "./CommunityInteraction.js";
import PrescriptionManagement from "./PrescriptionManagement.js";
import Message from "../Chat.js";

function Patient() {
  const [activeComponent, setActiveComponent] = useState("Symptom");
  const navigate = useNavigate();
  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "Symptom":
        return <SymptomChecker></SymptomChecker>;
      case "Medication":
        return <MedicationReminders></MedicationReminders>;
      case "PHR":
        return <PersonalHealthRecords></PersonalHealthRecords>;
      case "Appointment":
        return <AppointmentManagement></AppointmentManagement>;
      case "Community":
        return <CommunityInteraction></CommunityInteraction>;
      case "Prescription":
        return <PrescriptionManagement></PrescriptionManagement>;
      case "Chat":
        return <Message></Message>;
      default:
        return <SymptomChecker></SymptomChecker>;
    }
  };
  const handleLogout = () => {
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("userRole");
    navigate("/login");
  };

  return (
    <div className="patient-container">
      <div className="top-bar">
        <h1 className="patient-heading">Welcome Patient!</h1>

        <div className="top-bar-buttons">
          <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={handleLogout}
          >
            <FaSignOutAlt className="icon" /> Logout
          </div>
        </div>
      </div>
      <div>
        <div className="patient-sidebarContainer">
          <div className="patient-sidebar">
            <button
              onClick={() => handleButtonClick("Symptom")}
              className={`sidebar-button ${
                activeComponent === "Symptom" ? "active" : ""
              }`}
            >
              <i className="fa-patient-icon">
                <FaStethoscope />{" "}
              </i>
              Symptom Checker
            </button>
            <button
              onClick={() => handleButtonClick("Medication")}
              className={`sidebar-button ${
                activeComponent === "Medication" ? "active" : ""
              }`}
            >
              <i className="fa-patient-icon">
                <FaCapsules />
              </i>
              Medication Reminders
            </button>
            <button
              onClick={() => handleButtonClick("PHR")}
              className={`sidebar-button ${
                activeComponent === "PHR" ? "active" : ""
              }`}
            >
              <i className="fa-patient-icon">
                <FaIdCard />
              </i>
              Personal Health Records (PHR)
            </button>
            <button
              onClick={() => handleButtonClick("Appointment")}
              className={`sidebar-button ${
                activeComponent === "Appointment" ? "active" : ""
              }`}
            >
              <i className="fa-patient-icon">
                <FaCalendarPlus />
              </i>
              Appointment Management
            </button>
            <button
              onClick={() => handleButtonClick("Community")}
              className={`sidebar-button ${
                activeComponent === "Community" ? "active" : ""
              }`}
            >
              <i className="fa-patient-icon">
                <FaHandshake />
              </i>
              Community Interaction
            </button>
            <button
              onClick={() => handleButtonClick("Prescription")}
              className={`sidebar-button ${
                activeComponent === "Prescription" ? "active" : ""
              }`}
            >
              <i className="fa-patient-icon">
                <FaPills />
              </i>
              Manage Prescriptions
            </button>
            <button
              onClick={() => handleButtonClick("Chat")}
              className={`sidebar-button ${
                activeComponent === "Chat" ? "active" : ""
              }`}
            >
              Chat
            </button>
          </div>
          <div className="patient-sidebarContent">{renderComponent()}</div>
        </div>
      </div>
    </div>
  );
}

export default Patient;
