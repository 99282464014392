/* Samyukth challa 1002075109
 */
const createComplianceData = () => {
  const dummyData = [];
  const complianceAreas = [
    "HIPAA",
    "OSHA",
    "Hazardous Waste",
    "Medical Records",
    "Patient Safety",
  ];
  const complianceStatus = ["Compliant", "Non-Compliant", "In Progress"];
  const taskStatus = ["Due", "Completed"];

  for (let i = 1; i <= 10; i++) {
    dummyData.push({
      id: i,
      department: `Department ${i}`,
      complianceArea:
        complianceAreas[Math.floor(Math.random() * complianceAreas.length)],
      complianceStatus:
        complianceStatus[Math.floor(Math.random() * complianceStatus.length)],
      lastInspection: getRandomDate(new Date(2020, 0, 1), new Date()),
      taskName: `Task ${i}`,
      taskStatus: taskStatus[Math.floor(Math.random() * taskStatus.length)],
    });
  }

  return dummyData;
};

const getRandomDate = (start, end) => {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
};

export default createComplianceData;
