/*
  BOMMALI SNEHASHISH 
  1002127072
*/
//AboutUs.js

import React from 'react';
import './AboutUs.css';
import Footer from './Footer.js'

function AboutUs() {
    return (
        <div className="about-us-container">
            <header className="about-us-header">
                <h1>About Us</h1>
            </header>
            <section className="about-us-content">
                <div className="about-us-text">
                    <h2>Our Mission</h2>
                    <p>At SmartHealth Hub, our mission is to empower individuals to take control of their health journey by providing innovative solutions that enhance healthcare management and improve overall well-being.</p>
                    <h2>Our Vision</h2>
                    <p>We envision a world where everyone has access to personalized healthcare solutions that enable them to lead healthier and happier lives.</p>
                    <h2>Our Values</h2>
                    <ul>
                        <li><strong>Excellence:</strong> We are committed to delivering excellence in everything we do, from product development to customer service.</li>
                        <li><strong>Innovation:</strong> We embrace innovation and continuously strive to improve and evolve our solutions to meet the changing needs of our users.</li>
                        <li><strong>Empowerment:</strong> We believe in empowering individuals to make informed decisions about their health and well-being.</li>
                        <li><strong>Collaboration:</strong> We value collaboration and work closely with healthcare professionals, researchers, and partners to drive positive change in the healthcare industry.</li>
                        <li><strong>Integrity:</strong> We conduct our business with the highest standards of integrity, honesty, and ethical behavior.</li>
                    </ul>
                </div>
               
            </section>
            < Footer />
        </div>
    );
}

export default AboutUs;
