/*
Thirunagari
Sulakshana
1002190993
*/
import React, { useEffect, useState } from "react";
import "./Patient.css";
import { FaTrash, FaEdit, FaCalendarPlus } from "react-icons/fa";
import axios from "axios";
import url from "../../url";

const AppointmentManagement = () => {
  const [appointments, setAppointments] = useState([]);
  const [formData, setFormData] = useState({ date: "", time: "", provider: "", location: "", });
  const [appointmentError, setAppointmentError] = useState("");
  const [edit, isEdit] = useState(false);
  const user = parseInt(sessionStorage.getItem('userId'));
  const [loading, setLoading] = useState(false);

  const fetchAppointmentsData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${url}/appointments/patient/${user}`);
      console.log(response);
      setLoading(false)
      setAppointments(response.data.data);
    } catch (error) {
        setLoading(false)
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAppointmentsData();
  }, []);

  const handleInputChange = (e) => {
    const isValidDate = e.target.name === 'date' ? (new Date(e.target.value) >= new Date() ? true: false) : false;
    console.log(isValidDate)
    if(!isValidDate && e.target.name === 'date'){
        return setAppointmentError("Date cannot be before than today's date");
    }
    setAppointmentError("");
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = (appointment) => {
    isEdit(true);
    setFormData({id:appointment.id, date: appointment.date, time: appointment.time, provider: appointment.provider, location: appointment.location });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if ( !formData.date || !formData.time || !formData.provider || !formData.location ) {
        setAppointmentError("All fields are required.");
    }
     else if (edit) {
      try {
        const response = await axios.put(`${url}/appointments/${formData.id}`, {
          ...formData,
          patient: user
        });
        isEdit(false);
        setFormData({ date: "", time: "", provider: "", location: "" });
        fetchAppointmentsData()
      } catch (error) {
        console.log(error);
      }
    } else  {
      try {
        formData.doctorName = formData.provider;
        const response = await axios.post(`${url}/appointments`, {
          ...formData,
          patient: user,
          status: "Scheduled",
        });
        setFormData({ date: "", time: "", provider: "", location: "" });
        isEdit(false);
        fetchAppointmentsData()
      } catch (error) {
        console.log(error);
      }}
  };

  const handleDelete = async (appointment) => {
    setAppointmentError("");
    const response = await axios.delete(`${url}/appointments/${appointment.id}`, {});
    fetchAppointmentsData();
    
  };

  const handleNewappointment = () => {
    isEdit(false);
    setAppointmentError("");
    setFormData({ date: "", time: "", provider: "", location: "" });
  };

  return (
    <div>
      <h2>
        <FaCalendarPlus /> Appointment Management
      </h2>
      <p>
        Please schedule or cancel any appointments with the healthcare
        providers. Our system will help to assist you further.
      </p>
      <div className="patient-appointmentContainer">
        <h3>{edit ? `Update Appointment` : `Book Appointment`}</h3>
        <form noValidate onSubmit={handleFormSubmit}>
          {appointmentError !== "" && (
            <span style={{ color: "red" }}>{appointmentError}</span>
          )}

          <div className="form-group">
            <label>Date:</label>
            <input type="date" name="date" required value={formData.date} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Time:</label>
            <input type="time" name="time" required value={formData.time} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Provider:</label>
            <input type="text" name="provider" required value={formData.provider} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Location:</label>
            <input type="text" name="location" required value={formData.location} onChange={handleInputChange} />
          </div>
          <button type="submit">
            {edit ? `Update Appointment` : `Book Appointment`}
          </button>
          {edit && (
            <button onClick={handleNewappointment} type="submit">
              New Appointment
            </button>
          )}
        </form>
      </div>
      {appointments?.length > 0 && !loading &&
              <>
                  <h3 className="patientTitles">Scheduled Appointments</h3>
                  <div className="patientAppointmentsList">
                      {appointments?.map((appointment, index) => (
                          <div
                              key={appointment.id}
                              className={`scheduledAppointmentsContainer ${appointment.status === "Scheduled" ? "sucess" : appointment.status === "pending" ? "pending" : ""}`}
                          >
                              <p>Date: {appointment.date}</p>
                              <p>Time: {appointment.time}</p>
                              <p>Provider: {appointment.provider}</p>
                              <p>Location: {appointment.location}</p>
                              <strong>Status: {appointment.status}</strong>
                              <button onClick={() => handleUpdate(appointment)}>
                                  <i className="fa-patient-icon">
                                      <FaEdit />
                                      <span>Edit</span>
                                  </i>
                              </button>
                              <button disabled={edit} onClick={() => handleDelete(appointment)}>
                                  <i className="fa-patient-icon">
                                      <FaTrash color={edit ? "gray" : "black"} />
                                      <span>Delete</span>
                                  </i>
                              </button>
                          </div>
                      ))}
                  </div>
              </>
          }
          {loading && <div className='patientSpinner'></div>}
    </div>
  );
};

export default AppointmentManagement;
