/*Sangram Yathati- 1002168539*/

// src/components/HealthcareProvider/EPrescriptions/EPrescriptions.js
import React, { useEffect, useState } from "react";
import "./EPrescriptions.css";
import axios from "axios";
import url from "../../../url";

const Prescription = ({ prescription, onDelete }) => {
  return (
    <div className="prescription-item">
      <p>
        <strong>Patient:</strong> {prescription.patient}
      </p>
      <p>
        <strong>Medication:</strong> {prescription.medication}
      </p>
      <p>
        <strong>Dosage:</strong> {prescription.dosage}
      </p>
      <p>
        <strong>Frequency:</strong> {prescription.frequency}
      </p>
      <p>
        <strong>Notes:</strong> {prescription.notes}
      </p>
    </div>
  );
};

const EPrescriptions = () => {
  const [patients, setPatients] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [newPrescription, setNewPrescription] = useState({
    patient: "",
    medication: "",
    dosage: "",
    frequency: "",
    notes: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPrescription({ ...newPrescription, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!newPrescription.patientName || !newPrescription.medication) return; // Basic validation
    try {
      const response = await axios.post(`${url}/prescriptions`, {
        ...newPrescription,
      });
      console.log(response);
      
    } catch (error) {
      console.log(error);
    }
    // setPrescriptions([
    //   ...prescriptions,
    //   { ...newPrescription, id: Date.now() },
    // ]);
    setNewPrescription({
      patient: "",
      medication: "",
      dosage: "",
      frequency: "",
      notes: "",
    }); // Reset form
  };

  const deletePrescription = (id) => {
    setPrescriptions(
      prescriptions.filter((prescription) => prescription.id !== id)
    );
  };

  const fetchPatients = async () => {
    try {
      const response = await axios.get(`${url}/user-two`);
      console.log(response);
      const filteredData = response.data.filter(
        (item) => item.role === "patient"
      );
      setPatients(filteredData);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPatients();
  }, []);
  const fetchPrescriptions = async () => {
    try {
      const response = await axios.get(`${url}/prescriptions`);
      console.log(response);
      setPrescriptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    
    fetchPrescriptions();
  }, []);

  return (
    <div className="e-prescriptions-container">
      <h2>E-Prescriptions</h2>
      <form onSubmit={handleSubmit} className="add-prescription-form">
        <label>Patient Name</label>
        <select
          name="patient"
          style={{ height: "4rem" }}
          value={newPrescription.patient}
          placeholder="Patient Name"
          onChange={handleInputChange}
          required
        >
          <label>Patient Name</label>
          {patients.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>

        <input
          type="text"
          name="medication"
          value={newPrescription.medication}
          onChange={handleInputChange}
          placeholder="Medication"
          required
        />
        <input
          type="text"
          name="dosage"
          value={newPrescription.dosage}
          onChange={handleInputChange}
          placeholder="Dosage"
        />
        <input
          type="text"
          name="frequency"
          value={newPrescription.frequency}
          onChange={handleInputChange}
          placeholder="Frequency"
        />
        <textarea
          name="notes"
          value={newPrescription.notes}
          onChange={handleInputChange}
          placeholder="Notes"
        />
        <button type="submit" className="add-button">
          Add Prescription
        </button>
      </form>
      {prescriptions.map((prescription) => (
        <Prescription
          key={prescription.id}
          prescription={prescription}
          onDelete={deletePrescription}
        />
      ))}
    </div>
  );
};

export default EPrescriptions;
