/* Samyukth challa 1002075109
 */ import React from "react";
import "../FacilityManagement/index.css";
import ComplianceTable from "./Table";
import { Grid } from "@mui/material";
import createComplianceData from "./Table/data";
export default function index() {
  const data = createComplianceData();
  return (
    <div>
      <div className="admin-container">
        <Grid container px={4}>
          <ComplianceTable dummydata={data} />
        </Grid>
      </div>
    </div>
  );
}
