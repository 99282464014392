// Samyukth challa 1002075109

// Importing necessary modules and components
import React from "react"; // React library import for building user interfaces
import '../FacilityManagement/index.css' // Importing CSS for styling the facility management component
import FacilityTable from "./Table"; // Importing a table component, presumably for displaying facility-related data
import { Grid } from "@mui/material"; // Importing Grid from MUI, a React UI framework, for layout structure

// The default export of the file is a functional component named 'index'
export default function index() {
  // The component returns JSX to be rendered
  return (
    // Wrapping div for the component
    <div>
      {/* Div with a class for styling. Likely serves as a container for the facility management section */}
      <div className="admin-container">
        {/* Grid container from MUI to structure the layout, with padding on the x-axis */}
        <Grid container px={4}>
          {/* FacilityTable component is inserted here to display facility data in a structured table */}
          <FacilityTable/>
        </Grid>
      </div>
    </div>
  );
}
