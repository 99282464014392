/*
  BOMMALI SNEHASHISH 
  1002127072
*/
// src/components/Administrator/DataOversight/DataMonitoring.js
import React, { useState, useEffect } from 'react';
import './DataMonitoring.css';
import { Line } from 'react-chartjs-2';
import ChartJS from 'chart.js/auto';

const Metric = ({ title, value, onClick }) => {
  let status = "metric"; // Default status class
  if (title === "Data Breach Incidents" && value > 0) {
    status += " critical";
  } else if (title === "System Uptime" && value < 99.95) {
    status += " warning";
  } else if (title === "Security Alerts" && value > 10) {
    status += " critical";
  } else if (title === "Privacy Compliance Status" && value !== "Compliant") {
    status += " warning";
  }

  // Handle click event
  const handleClick = (e) => {
    // Prevent any default action or propagation that might cause global effects
    e.preventDefault();
    e.stopPropagation();

    // Call the onClick handler if provided
    if (onClick) onClick(title);

    // Implement any additional logic here, for example, toggling a strike-through effect
    // or marking the metric as acknowledged.
  };

  return (
    <div className={status}>
      <h3>{title}</h3>
      <p>{value !== null ? value : 'N/A'}</p>
    </div>
  );
};

function DataMonitoring() {
  const [metrics, setMetrics] = useState({
    dataBreachIncidents: null,
    systemUptime: null,
    securityAlerts: null,
    privacyCompliance: 'N/A',
  });
  const [realTimeData, setRealTimeData] = useState([]);

  useEffect(() => {
    fetch('/api/data-monitoring')
      .then(response => response.json())
      .then(data => setMetrics(data))
      .catch(error => console.error('Error fetching data monitoring metrics:', error));

    const intervalId = setInterval(() => {
      fetch('/api/real-time-data')
        .then(response => response.json())
        .then(data => setRealTimeData(prevData => [...prevData, data]))
        .catch(error => console.error('Error fetching real-time data:', error));
    }, 5000); // Fetch updates every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  const chartData = {
    labels: realTimeData.map((_, index) => `Update ${index + 1}`),
    datasets: [
      {
        label: 'System Load',
        data: realTimeData.map(data => data.systemLoad),
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
      },
      {
        label: 'Server Errors',
        data: realTimeData.map(data => data.serverErrors),
        fill: false,
        borderColor: 'rgba(255,99,132,1)',
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category',
      },
      y: {
        type: 'linear',
      },
    },
  };

  return (
    <div className="data-monitoring-container">
      <h2>Data Monitoring Dashboard</h2>
      
      <div className="metrics">
        <Metric title="Data Breach Incidents" value={metrics.dataBreachIncidents} />
        <Metric title="System Uptime" value={metrics.systemUptime} />
        <Metric title="Security Alerts" value={metrics.securityAlerts} />
        <Metric title="Privacy Compliance Status" value={metrics.privacyCompliance} />
      </div>
      <div className="real-time-chart">
        <h3>Real-Time System Load and Server Errors</h3>
        <Line data={chartData} options={chartOptions} />
      </div>
      
    </div>
  );
}

export default DataMonitoring;
