/*
Thirunagari
Sulakshana
1002190993
*/
import React, { useEffect, useState } from "react";
import "./Patient.css";
import { FaUsers, FaThumbsUp, FaThumbsDown, FaPlus, FaEdit, FaTrash, } from "react-icons/fa";
import Modal from "@mui/material/Modal";
import axios from "axios";
import url from "../../url";

const CommunityInteraction = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [communications, setCommunications] = useState();
  const [commComment, setCommComment] = useState("");
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ content: "", heading: "" });
  const [articleError, setArticleError] = useState("");
  const [edit, isEdit] = useState(false);
  const [articleId, setArticleId] = useState();
  const [loading, setLoading] = useState(false);

  //   const handleSearch = () => {
  //     const filteredCommunications = communityInteractionsDummyData.filter(
  //       (communication) =>
  //         communication.content.toLowerCase().includes(searchTerm.toLowerCase())
  //     );

  //     const highlightedCommunications = filteredCommunications.map(
  //       (communication) => {
  //         const regex = new RegExp(`(${searchTerm})`, "gi");
  //         const parts = communication.content.split(regex);
  //         return {
  //           ...communication,
  //           highlightedContent: parts.map((part, index) =>
  //             regex.test(part) ? (
  //               <span key={index} style={{ backgroundColor: "yellow" }}>
  //                 {part}
  //               </span>
  //             ) : (
  //               part
  //             )
  //           ),
  //         };
  //       }
  //     );

  //     setCommunications(highlightedCommunications);
  //   };

  //   const handleClearSearch = () => {
  //     setSearchTerm("");
  //     setCommunications(communityInteractionsDummyData);
  //   };

  const fetchPostData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${url}/community/patient`);
      console.log(response);
      setLoading(false)
      setCommunications(response.data);
    } catch (error) {
        setLoading(false)
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPostData();
  }, []);

  const handleCommentSubmit = async (e, communicationId) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${url}/community/patient/comment`,
        {
          comment: commComment,
          author: sessionStorage.getItem("name") || "user",
          article: communicationId,
        }
      );
      console.log(response);
      fetchPostData();
    } catch (error) {
      console.log(error);
    }
  };

  //   const handleSocial = (communicationId, action) => {
  //     setCommunications((prevCommunications) =>
  //       prevCommunications.map((comm) => {
  //         if (comm.id === communicationId) {
  //           const updatedSocialProps = { ...comm.socialProps };
  //           const updatedUserInteraction = { ...comm.userInteraction };

  //           if (action === "like") {
  //             if (!comm.userInteraction.like) {
  //               updatedSocialProps.like += 1;
  //               updatedSocialProps.dislike -= updatedUserInteraction.dislike
  //                 ? 1
  //                 : 0;
  //               updatedUserInteraction.like = true;
  //               updatedUserInteraction.dislike = false;
  //             } else {
  //               updatedSocialProps.like -= 1;
  //               updatedUserInteraction.like = false;
  //             }
  //           } else if (action === "dislike") {
  //             if (!comm.userInteraction.dislike) {
  //               updatedSocialProps.dislike += 1;
  //               updatedSocialProps.like -= updatedUserInteraction.like ? 1 : 0;
  //               updatedUserInteraction.dislike = true;
  //               updatedUserInteraction.like = false;
  //             } else {
  //               updatedSocialProps.dislike -= 1;
  //               updatedUserInteraction.dislike = false;
  //             }
  //           }

  //           return {
  //             ...comm,
  //             socialProps: updatedSocialProps,
  //             userInteraction: updatedUserInteraction,
  //           };
  //         }
  //         return comm;
  //       })
  //     );
  //   };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!formData.title || !formData.content) {
      setArticleError("All fields are required.");
    } else if (edit) {
      const updatedArticles = communications.map((communication) =>
        communication.id === articleId
          ? { ...communication, ...formData }
          : communication
      );
      setCommunications(updatedArticles);
    } else {
      //   const newArticle = {
      //     ...formData,
      //     comments: [],
      //   };
      //   setCommunications([newArticle, ...communications]);
      //   setFormData({ content: "", heading: "" });
      try {
        formData.author = sessionStorage.getItem("name") || "user";
        const response = await axios.post(`${url}/community/patient`, {
          ...formData,
        
        });
        console.log(response);
        alert("Post Created");
        fetchPostData();
        setOpen(false);
      } catch (error) {
        console.log(error);
      }
    }
    isEdit(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  //   const handleEdit = (communication) => {
  //     setOpen(true);
  //     isEdit(true);
  //     setFormData({
  //       content: communication.content,
  //       heading: communication.heading,
  //     });
  //     setArticleId(communication.id);
  //   };

  //   const handleDelete = (index) => {
  //     setArticleError("");
  //     const updatedArticles = [...communications];
  //     updatedArticles.splice(index, 1);
  //     setCommunications(updatedArticles);
  //   };

  return (
    <div>
      <h2>
        <FaUsers /> Community Interaction
      </h2>
      <p> Here you can participate in health forums to discuss health-related topics, share experiences, and seek advice from peers or healthcare professionals. </p>
      <div>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="patientModalContent">
            <h2 id="modal-modal-title">Add Article</h2>
            <form noValidate onSubmit={handleFormSubmit}>
              {articleError !== "" && (
                <span style={{ color: "red" }}>{articleError}</span>
              )}
              <div className="form-group">
                <label>Title:</label>
                <input type="text" name="title" required value={formData.title} onChange={handleInputChange} />
              </div>
              <div className="form-group">
                <label>Content:</label>
                <textarea
                  name="content"
                  required
                  value={formData.content}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <button type="submit">{edit ? `Update` : `Submit`}</button>
              <button onClick={() => setOpen(false)}>Close</button>
            </form>
          </div>
        </Modal>
      </div>
      {loading && <div className='patientSpinner'></div>}
      <div className="patientModal">
        <button onClick={() => setOpen(true)}>
          <i className="fa-patient-icon">
            <FaPlus /> Add Article
          </i>
        </button>
      </div>
      <div className="communityInteractionContainer">
        {communications?.map((communication) => (
          <div className="patientCardContainer">
            <div className="communityInteractionCard">
              <div className="communityInteractionCardHeader">
                <h4>{communication.title}</h4> 
                <h4>author :{communication.author}</h4>
              </div>
              <div className="communityInteractionCardBody">
                <p>
                  {communication.highlightedContent || communication.content}
                </p>
              </div>
            </div>
            <div className="communityInteractionComments">
              <h3>Comments:</h3>
              {communication.comments.map((comment) => (
                <div>
                  <h4>USER :{comment.author}</h4>
                  <span>{comment.comment}</span>
                </div>
              ))}
            </div>
            <div>
              <form noValidate>
                <div className="form-group">
                  <label>Enter your comment here:</label>
                  <textarea
                    value={commComment}
                    onChange={(e) => setCommComment(e.target.value)}
                  ></textarea>
                </div>
                <button
                  onClick={(e) => handleCommentSubmit(e, communication.id)}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommunityInteraction;
