/*
Thirunagari
Sulakshana
1002190993
*/
import React, { useEffect, useState } from "react";
import "./Patient.css";
import { FaPills, FaEdit, FaTrash, FaPlus, FaSyncAlt, FaCheck, } from "react-icons/fa";
import { patientHealthRecordDummyData, medicationsDummyData } from "./mocks";
import Modal from "@mui/material/Modal";
import axios from "axios";
import url from "../../url";

const PrescriptionManagement = () => {
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
  const [formData, setFormData] = useState({ name: "", dosage: "", frequency: "", start: "", end: "", });
  const [prescriptionError, setPrescriptionError] = useState("");
  const [edit, isEdit] = useState(false);
  const [currentPrescriptions, setCurrentPrescriptions] = useState();
  const [prescriptionId, setPrescriptionId] = useState();
  const [requestRefill, setRequestRefill] = useState(false);

  const [openReminderModal, setOpenReminderModal] = useState(false);
  const [reminderFormData, setReminderFormData] = useState({ name: "", dosage: "", time: "", type: "", });
  const [reminderError, setReminderError] = useState("");
  const [reminderEdit, isReminderEdit] = useState(false);
  const [currentReminders, setCurrentReminders] = useState();
  const [reminderId, setReminderId] = useState();

  const [prescriptionLoading, setPrescriptionLoading] = useState(false);
  const [reminderLoading, setReminderLoading] = useState(false);

  const fetchPrescriptionData = async () => {
    setPrescriptionLoading(true);
      try {
          const response = await axios.get(`${url}/prescriptions/patient/${sessionStorage.getItem("userId")}`);
          console.log(response);
          setPrescriptionLoading(false)
          setCurrentPrescriptions(response.data?.data);
      } catch (error) {
          setPrescriptionLoading(false)
          console.log(error);
      }
  };

  const fetchReminders = async () => {
      setReminderLoading(true);
      try {
          const response = await axios.get(`${url}/patients/medication-reminder/${sessionStorage.getItem("userId")}`);
          console.log(response);
          setReminderLoading(false)
          setCurrentReminders(response.data?.medicationReminders);
      } catch (error) {
          setReminderLoading(false)
          console.log(error);
      }
  };

  useEffect(() => {
    fetchPrescriptionData();
    fetchReminders();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if ( !formData.name || !formData.dosage || !formData.frequency || !formData.start || !formData.end ) {
      setPrescriptionError("All fields are required.");
    } else if (edit) {
      try {
        const userId = sessionStorage.getItem("userId");
        formData.patient = userId;
        const response = await axios.put(`${url}/prescriptions/${formData.id}`, {
          ...formData,
          patient: sessionStorage.getItem("userId"),
        });
        console.log(response);
        isEdit(false);
        setOpenPrescriptionModal(false);
        fetchPrescriptionData();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await axios.post(`${url}/prescriptions`, {
          ...formData,
          patient: sessionStorage.getItem("userId"),
        });
        console.log(response);
        setFormData({ name: "", dosage: "", frequency: "", start: "", end: "", });
        isEdit(false);
        setOpenPrescriptionModal(false);
        fetchPrescriptionData();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleReminderFormSubmit = async (e) => {
    e.preventDefault();
    if ( !reminderFormData.name || !reminderFormData.dosage || !reminderFormData.time ) {
      setReminderError("All fields are required.");
    } else if (reminderEdit) {
      try {
        const response = await axios.put(`${url}/patients/medication-reminder/${reminderFormData.id}`, {
          ...reminderFormData,
        });
        console.log(response);
        isReminderEdit(false);
        fetchReminders();
        setOpenReminderModal(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const userId = sessionStorage.getItem("userId");
        reminderFormData.patient = userId;
        const response = await axios.post(`${url}/patients/medication-reminder`, {
          ...reminderFormData,
        });
        console.log(response);
        setReminderFormData({ name: "", dosage: "", time: "", type: "" });
        isReminderEdit(false);
        fetchReminders();
        setOpenReminderModal(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleReminderInputChange = (e) => {
    const { name, value } = e.target;
    setReminderFormData({ ...reminderFormData, [name]: value });
  };

  const handleEdit = (prescription) => {
    setOpenPrescriptionModal(true);
    isEdit(true);
    setFormData({ id: prescription.id, name: prescription.name, dosage: prescription.dosage, frequency: prescription.frequency, start: prescription.start, end: prescription.end, });
    setPrescriptionId(prescription.id);
  };

  const handleReminderEdit = (reminder) => {
    setOpenReminderModal(true);
    isReminderEdit(true);
    setReminderFormData({ id: reminder.id, name: reminder.name, dosage: reminder.dosage, time: reminder.time, type: "", });
    setReminderId(reminder.id);
  };

  const handleDelete = async(prescription) => {
    try{
      const response = await axios.delete(`${url}/prescriptions/${prescription.id}`);
      console.log(response);
      fetchPrescriptionData();
    }catch(error){
      console.log(error);
    }
  };

  const handleReminderDelete =async (reminder) => {
    try{
      const response = await axios.delete(`${url}/patients/medication-reminder/${reminder.id}`);
      console.log(response);
      fetchReminders();
    }catch(error){
      console.log(error);
    }
  };

  const handleOpen = () => {
    setOpenPrescriptionModal(true);
    isEdit(false);
    setFormData({ name: "", dosage: "", frequency: "", start: "", end: "" });
    setPrescriptionId();
  };

  const handleReminderOpen = () => {
    setOpenReminderModal(true);
    isReminderEdit(false);
    setReminderFormData({ name: "", dosage: "", time: "", type: "" });
    setReminderId();
  };

  const handleClose = () => {
    setOpenPrescriptionModal(false);
    isEdit(false);
    setFormData({ name: "", dosage: "", frequency: "", start: "", end: "" });
    setPrescriptionId();
  };

  const handleReminderClose = () => {
    setOpenReminderModal(false);
    isReminderEdit(false);
    setFormData({ name: "", dosage: "", time: "", type: "" });
    setReminderId();
  };

  return (
    <div className="prescriptionManagement">
      <h2>
        <FaPills /> Prescription Management
      </h2>
      <p>
        Here you can manage your prescription, set medication reminders and
        request for refills.
      </p>
      <div>
        <Modal open={openPrescriptionModal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
          <div className="patientModalContent">
            <h3 id="modal-modal-title">Add Prescription</h3>
            <form id="prescriptions" noValidate onSubmit={handleFormSubmit}>
              {prescriptionError !== "" && (
                <span style={{ color: "red" }}>{prescriptionError}</span>
              )}
              <div className="form-group">
                <label>Name:</label>
                <input type="text" name="name" required value={formData.name} onChange={handleInputChange} />
              </div>
              <div className="form-group">
                <label>Dosage:</label>
                <input type="text" name="dosage" required value={formData.dosage} onChange={handleInputChange} />
              </div>
              <div className="form-group">
                <label>Frequency:</label>
                <input type="text" name="frequency" required value={formData.frequency} onChange={handleInputChange} />
              </div>
              <div className="form-group">
                <label>Start Date:</label>
                <input type="date" name="start" required value={formData.start} onChange={handleInputChange} />
              </div>
              <div className="form-group">
                <label>End Date:</label>
                <input type="date" name="end" required value={formData.end} onChange={handleInputChange} />
              </div>
              <button type="submit">{edit ? `Update` : `Submit`}</button>
              <button onClick={handleClose}>Close</button>
            </form>
          </div>
        </Modal>
      </div>
      
      <div className="prescriptionContainer">
        <h3>Ongoing Prescriptions:</h3>

        <button onClick={handleOpen}>
          <i className="fa-patient-icon">
            <FaPlus /> Add Prescription
          </i>
        </button>
        {prescriptionLoading && <div className='patientSpinner'></div>}
        <div className="prescriptionCurrent">
          {currentPrescriptions?.map((medication) => (
            <div className="prescriptionCard">
              <h4>{medication.name}</h4>
              <button onClick={() => handleDelete(medication)}>
                <i className="fa-patient-icon">
                  <FaTrash />
                </i>
              </button>
              <button onClick={() => handleEdit(medication)}>
                <i className="fa-patient-icon">
                  <FaEdit />
                </i>
              </button>
              <p>Dosage: {medication.dosage}</p>
              <p>Frequency: {medication.frequency}</p>
              <p>
                From:{medication.start} To:{medication.end}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div>
        <Modal open={openReminderModal} onClose={handleReminderClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
          <div className="patientModalContent">
            <h3 id="modal-modal-title">Add Reminder</h3>
            <form id="reminders" noValidate onSubmit={handleReminderFormSubmit}>
              {reminderError !== "" && (
                <span style={{ color: "red" }}>{reminderError}</span>
              )}
              <div className="form-group">
                <label>Name:</label>
                <input type="text" name="name" required value={reminderFormData.name} onChange={handleReminderInputChange} />
              </div>
              <div className="form-group">
                <label>Dosage:</label>
                <input type="text" name="dosage" required value={reminderFormData.dosage} onChange={handleReminderInputChange} />
              </div>
              <div className="form-group">
                <label>Time:</label>
                <input type="time" name="time" required value={reminderFormData.time} onChange={handleReminderInputChange} />
              </div>
              <button type="submit">
                {reminderEdit ? `Update` : `Submit`}
              </button>
              <button onClick={handleReminderClose}>Close</button>
            </form>
          </div>
        </Modal>
      </div>
      
      <div className="prescriptionContainer">
        <h3>Medication Reminders:</h3>
        <button onClick={handleReminderOpen}>
          <i className="fa-patient-icon">
            <FaPlus /> Add Reminder
          </i>
        </button>
        {reminderLoading && <div className='patientSpinner'></div>}
        <div className="prescriptionCurrent">
          {currentReminders?.map((medication) => (
            <div key={medication.id} className="prescriptionCard">
              <h3>{medication.name}</h3>
              <button onClick={() => handleReminderDelete(medication)}>
                <i className="fa-patient-icon">
                  <FaTrash />
                </i>
              </button>
              <button onClick={() => handleReminderEdit(medication)}>
                <i className="fa-patient-icon">
                  <FaEdit />
                </i>
              </button>
              <p>Dosage: {medication.dosage}</p>
              <p>Scheduled Time: {medication.time}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrescriptionManagement;
