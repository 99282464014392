/*
  BOMMALI SNEHASHISH 
  1002127072
*/
import React, { useState, useEffect } from "react";
import "./ReportGeneration.css";
import axios from "axios";
import url from "../../../url";

function ReportGeneration() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newReportParams, setNewReportParams] = useState({
    startDate: "",
    endDate: "",
    userActivity: false,
    healthTrends: false,
    systemPerformance: false,
  });
  const [reportGenerated, setReportGenerated] = useState(false);
  const [generatedReport, setGeneratedReport] = useState([]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${url}/user-two`);
      console.log(response);
      setUsers(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchReport = async (id) => {
    try {
      const response = await axios.get(`${url}/reports/${id}`);
      console.log(response);
      setGeneratedReport(response.data);
      setReportGenerated(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    fetchReport(user.id);
  };

  const handleGenerateReport = async () => {
    try {
      const response = await axios.post(`${url}/reports`, {
        user: selectedUser.id,
        ...newReportParams,
      });
      console.log(response);
      fetchReport(selectedUser.id);
    } catch (error) {
      console.log(error);
    }
    // const dummyReport = {
    //   user: selectedUser,
    //   startDate: newReportParams.startDate,
    //   endDate: newReportParams.endDate,
    //   userActivity: newReportParams.includeUserActivity
    //     ? "Dummy user activity data"
    //     : null,
    //   healthTrends: newReportParams.includeHealthTrends
    //     ? "Dummy health trend data"
    //     : null,
    //   systemPerformance: newReportParams.includeSystemPerformance
    //     ? "Dummy system performance data"
    //     : null,
    // };
    // setGeneratedReport(dummyReport);
    // setReportGenerated(true);
  };

  return (
    <div className="report-generation-container">
      <div className="user-list">
        <h3>Select User</h3>
        <ul>
          {users.map((user) => (
            <li key={user.id} onClick={() => handleUserSelect(user)}>
              {user.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="report-options">
        <h2>Generate Report</h2>
        <form>
          <label>
            Start Date:
            <input
              type="date"
              value={newReportParams.startDate}
              onChange={(e) =>
                setNewReportParams({
                  ...newReportParams,
                  startDate: e.target.value,
                })
              }
            />
          </label>
          <label>
            End Date:
            <input
              type="date"
              value={newReportParams.endDate}
              onChange={(e) =>
                setNewReportParams({
                  ...newReportParams,
                  endDate: e.target.value,
                })
              }
            />
          </label>
          <label>
            Include User Activity:
            <input
              type="checkbox"
              checked={newReportParams.includeUserActivity}
              onChange={(e) =>
                setNewReportParams({
                  ...newReportParams,
                  includeUserActivity: e.target.checked,
                })
              }
            />
          </label>
          <label>
            Include Health Trends:
            <input
              type="checkbox"
              checked={newReportParams.includeHealthTrends}
              onChange={(e) =>
                setNewReportParams({
                  ...newReportParams,
                  includeHealthTrends: e.target.checked,
                })
              }
            />
          </label>
          <label>
            Include System Performance:
            <input
              type="checkbox"
              checked={newReportParams.includeSystemPerformance}
              onChange={(e) =>
                setNewReportParams({
                  ...newReportParams,
                  includeSystemPerformance: e.target.checked,
                })
              }
            />
          </label>
          <button type="button" onClick={handleGenerateReport}>
            Generate Report
          </button>
        </form>
      </div>
      <div className="selected-user-info">
        {selectedUser && (
          <>
            <h3>Selected User: {selectedUser.name}</h3>
            {/* Additional information about the selected user can be displayed here */}
          </>
        )}
      </div>
      {reportGenerated &&
        generatedReport &&
        generatedReport.map((itm) => {
          return (
            <>
              <div className="generated-report">
                <h2>Generated Report</h2>
                <p>User: {selectedUser.name}</p>
                <p>Start Date: {itm?.startDate}</p>
                <p>End Date: {itm?.endDate}</p>
                {itm?.userActivity && <p>User Activity: {itm.userActivity}</p>}
                {itm?.healthTrends && <p>Health Trends: {itm?.healthTrends}</p>}
                {itm?.systemPerformance && (
                  <p>System Performance: {itm?.systemPerformance}</p>
                )}
              </div>
            </>
          );
        })}
    </div>
  );
}

export default ReportGeneration;
