/*
Thirunagari
Sulakshana
1002190993
*/
import React, { useEffect } from 'react';
import { Form, Modal, Input } from 'antd';
import axios from 'axios';
import url from '../../../url';
import { FaPlusCircle } from 'react-icons/fa';

const VitalSigns = () => {
    const [visible, setVisible] = React.useState(false)
    const [vitalSignForm] = Form.useForm();
    const [vitalSigns, setVitalSigns] = React.useState([]);
    const user = sessionStorage.getItem('userId');

    const getVitalSigns = async () => {
        try {
            const response = await axios.get(`${url}/patients/heath-record/vital-sign/${user}`);
            const data = response.data?.vitalSigns;
            setVitalSigns(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getVitalSigns();
    }, []);

    return (
        <div className='patient-table'>
            <h2 className='patientTitles'>Vital Signs</h2>
            <button className='phrFloatButton' onClick={() => setVisible(true)}><i className='fa-patient-icon'><FaPlusCircle /> </i>Add Vital Signs</button>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Blood Pressure</th>
                        <th>Heart Rate</th>
                        <th>Temperature (°C)</th>
                        <th>Weight (lb)</th>
                    </tr>
                </thead>
                <tbody>
                    {vitalSigns.map((record, index) => (
                        <tr key={index}>
                            <td>{record.date}</td>
                            <td>{record.bloodPressure}</td>
                            <td>{record.heartRate}</td>
                            <td>{record.temperature}</td>
                            <td>{record.weight}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal open={visible} title="Add Vital Sign" visible={false} onOk={() => { vitalSignForm.submit() }} onCancel={() => { setVisible(false) }}>
                <Form form={vitalSignForm} onFinish={
                    async (values) => {
                        try {
                            values.patient = user;
                            await axios.post(`${url}/patients/heath-record/vital-sign`, values);
                            getVitalSigns();
                            vitalSignForm.resetFields();
                            console.log(values);
                        } catch (error) {
                        }
                        setVisible(false);
                    }
                } >
                    <Form.Item name="date" label="Date" required={true} rules={[{ required: true, message: 'Please enter date' }]}>
                        <Input type="date" autoComplete='off'/>
                    </Form.Item>
                    <Form.Item name="bloodPressure" label="Blood Pressure" required={true} rules={[{ required: true, message: 'Please enter blood pressure' }]}>
                        <Input type="text" autoComplete='off'/>
                    </Form.Item>
                    <Form.Item name="heartRate" label="Heart Rate" required={true} rules={[{ required: true, message: 'Please enter heart rate' }]}>
                        <Input type="text" autoComplete='off'/>
                    </Form.Item>
                    <Form.Item name="temperature" label="Temperature" required={true} rules={[{ required: true, message: 'Please enter temperature' }]}>
                        <Input type="text" autoComplete='off'/>
                    </Form.Item>
                    <Form.Item name="weight" label="Weight" required={true} rules={[{ required: true, message: 'Please enter weight' }]}>
                        <Input type="text" autoComplete='off'/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default VitalSigns;