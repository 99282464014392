/*Sangram Yathati- 1002168539*/
import React, { useState } from 'react';
import './SecureMessaging.css';

const SecureMessaging = () => {
  const [messages, setMessages] = useState([
    { id: 0, text: "Hi, How can i help you?", sender: "Jane Smith", encrypted: false }
  ]);
  const [newMessage, setNewMessage] = useState('');

  const encryptMessage = (text) => {
    // This is a placeholder function to represent encryption
    return btoa(text); // Using Base64 just for demonstration
  };

  const decryptMessage = (text) => {
    // This is a placeholder function to represent decryption
    return atob(text); // Using Base64 just for demonstration
  };

  const sendMessage = () => {
    const encryptedMessage = encryptMessage(newMessage);
    setMessages([...messages, { id: messages.length, text: encryptedMessage, sender: "You", encrypted: true }]);
    setNewMessage('');
  };

  return (
    <div className="secure-messaging-container">
      <h1>Secure Messaging</h1>
      <div className="messages-list">
        {messages.map(message => (
          <div key={message.id} className="message">
            <strong>{message.sender}:</strong> <span>{message.encrypted ? decryptMessage(message.text) : message.text}</span>
          </div>
        ))}
      </div>
      <textarea
        className="message-input"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder="Type your message..."
      />
      <button className="send-button" onClick={sendMessage}>Send</button>
    </div>
  );
};

export default SecureMessaging;
