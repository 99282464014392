/*
  BOMMALI SNEHASHISH 
  1002127072
*/
// src/components/Administrator/UserManagement/EditUserModal.js
import axios from "axios";
import React, { useState } from "react";
import url from "../../../url";

function EditUserModal({ user, setUsers, closeModal }) {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [role, setRole] = useState(user.role);
  const [status, setStatus] = useState(user.status);
  const [password, setPassword] = useState(user.password);
  const [id, setId] = useState(user.id);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${url}/user-two`);
      console.log(response);
      setUsers(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.put(`${url}/user-two/update/${id}`, {
        name: name,
        email: email,
        status: status,
        role: role,
        password: password,
      });
      console.log(response);
      fetchUsers();
    } catch (error) {
      console.log(error);
    }
    closeModal();
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <h2>Edit User</h2>
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label>Role:</label>
        <select value={role} onChange={(e) => setRole(e.target.value)}>
          <option value="Admin">Admin</option>
          <option value="Patient">Patient</option>
          <option value="Pharmacist">Pharmacist</option>
          <option value="HealthAdmin">Health Admin</option>
          <option value="HealthcareProvider">Healthcare Provider</option>
        </select>
        <button onClick={handleSubmit}>Save Changes</button>
      </div>
    </div>
  );
}

export default EditUserModal;
