/*
Thirunagari
Sulakshana
1002190993
*/
import React, { useState } from 'react';
import './Patient.css';
import { FaHistory, FaPrescriptionBottle, FaHeartbeat, FaBicycle } from 'react-icons/fa';
import MedicalHistory from './Components/MedicalHistory';
import Prescription from './Components/Prescription';
import VitalSigns from './Components/VitalSigns';
import Exercise from './Components/Exercise';

const PersonalHealthRecords = () => {

  const [activeComponent,setActiveComponent] = useState('medicalHistory');

  return (
    <>
      <div className='phrRibbon'>
        <button className={`phrRibbon-button ${activeComponent === 'medicalHistory' ? 'active' : ''}`} onClick={() => setActiveComponent('medicalHistory')}> <i className='fa-patient-icon'><FaHistory /> </i>Medical History</button>
        <button className={`phrRibbon-button ${activeComponent === 'prescription' ? 'active' : ''}`} onClick={() => setActiveComponent('prescription')}> <i className='fa-patient-icon'><FaPrescriptionBottle /> </i>Prescriptions</button>
        <button className={`phrRibbon-button ${activeComponent === 'vitalSign' ? 'active' : ''}`} onClick={() => setActiveComponent('vitalSign')}> <i className='fa-patient-icon'><FaHeartbeat /> </i>Vital Signs</button>
        <button className={`phrRibbon-button ${activeComponent === 'exerciseData' ? 'active' : ''}`} onClick={() => setActiveComponent('exerciseData')}> <i className='fa-patient-icon'><FaBicycle /> </i>Exercise Data</button>
      </div>

      {activeComponent === 'medicalHistory' && <div><MedicalHistory></MedicalHistory></div>}
      {activeComponent === 'prescription' && <div><Prescription></Prescription></div>}
      {activeComponent === 'vitalSign' && <div><VitalSigns></VitalSigns></div>}
      {activeComponent === 'exerciseData' && <div><Exercise></Exercise></div>}
    </>
  )
}

export default PersonalHealthRecords