// Samyukth challa 1002075109

const createData = () => {
  const dummyData = [];
  const firstNames = ["John", "Jane", "Michael", "Emily", "David", "Sarah"];
  const lastNames = ["Smith", "Johnson", "Williams", "Jones", "Brown", "Davis"];
  for (let i = 1; i <= 20; i++) {
    const firstNameIndex = Math.floor(Math.random() * firstNames.length);
    const lastNameIndex = Math.floor(Math.random() * lastNames.length);

    dummyData.push({
      id: i,
      name: `${firstNames[firstNameIndex]} ${lastNames[lastNameIndex]}`,
      address: `Location ${i}`,
      email: `mail${i}@gmail.com`,
    });
  }

  return dummyData;
};

export default createData;
